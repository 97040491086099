@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "../../App.scss";

.hero-area-cont {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;

    height: calc(100% - 8px);
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    .hero-info {
        h2 {
            margin-bottom: 0;

            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 30px;
            color: #ffffff;
        }
        h5 {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
            text-transform: uppercase;
        }
    }
}

.overlay {
    height: calc(100% - 8px);
    background-color: rgba(0, 0, 0, 0.3);
}

/*
    VIDEO VOLUME BUTTON
*/
.volumeBtn {
    color: #fff;
    float: right;
    position: relative;
    right: 20px;
    top: -50px;
    transform: scale(1.5, 1.5);
    z-index: 100;
    .btn {
        height: 20px;
        width: 20px;

        padding: 0;

        background-color: transparent;
        border: none;
        border-radius: 20px;
    }
}

/*
    OUR SERVICES
*/
.services-area {
    h2 {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 30px;
        color: #000000;
    }
    p {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        color: #000000;
    }
}

/*
    OUR PROCESS
*/
.fun-facts-area {
    background-image: url('../../images/services-headers/development.jpg') ;
    background-size: cover;
    background-position: center center;
    .row.row-tb-30 {
        > [class*="col-"] {
            padding-top: 30px;
        }
    }
    h1 {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 30px;
    }
}

.our-process-steps {
    p {
        margin-bottom: 0;

        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        font-size: 16px;
    }
    h2 {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 18px;
    }
    .counter {
        position: relative;

        width: 40px;
        height: 40px;
        margin: auto;

        text-align: center;

        border: 1px solid #fff;
        border-radius: 100%;

        span {
            position: relative;
            top: 5px;
        }
    }
}

.paragraphs {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
}

.fun-facts-area {
    .btn {
        margin-top: 20px;

        width: 200px;

        font-family: 'Roboto', sans-serif;
        font-weight: 700;

        background-color: #6ac9c8;
        border: none;
        &:hover {
            background-color: #4ACA9D;
        }
    }
}

/*
    OUR CLIENTS
*/
.clients-area {
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    .slick-track {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .slick-slide {
            text-align: center;
            img {
                object-fit: scale-down;
               filter:grayscale(100%);
               -webkit-filter: grayscale(100%);
                margin: auto;
            }
        }
    }
    .chevroletLogo {
        transform: scale(0.6, 0.6);
    }
    .toyotaLogo {
        transform: scale(0.8, 0.8);
    }
    .samsungLogo {
        transform: scale(0.8, 0.8);
    }
    .omaLogo {
        transform: scale(0.6, 0.6);
    }
    .corusLogo {
        transform: scale(0.6, 0.6);
    }
    .eoneLogo {
        transform: scale(0.6, 0.6);
    }
    .ytvLogo {
        transform: scale(0.4, 0.4);
    }

}



/*
    GET IN TOUCH
*/
.contact-us-area {
    h2 {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 30px;
        color: #0f0f0f;
    }
    .contact-icon {
        i {
            margin-bottom: 0;

            color: #6ac9c8;

            box-shadow: none;
        }
    }
    h3 {
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        font-size: 18px;
        color: #000000;
    }
}

.contact-form {
    padding-top: 50px !important;
    padding: 16px;

    border: 2px solid #cccccc;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    .row > [class*="col-"] {
        padding-top: 0;
    }
    input {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 16px;

        border-radius: 0;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    .message-form {
        input {
            margin-bottom: 20px;
        }
        textarea {
            margin-bottom: 20px;

            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 16px;

            border-radius: 0;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
        .btn {
            width: 100%;

            font-family: 'Roboto', sans-serif;
            font-weight: 600;
            text-transform: initial;

            background-color: #6ac9c8;
            border: none;
            &:hover {
                background-color: #4ACA9D;
                border: none;
            }
            &:active {
                background-color: #5aaead;
            }
        }
    }
}

.form-control,
textarea {
    &:focus {
        border-color: #6ac9c8 !important;
    }
}

textarea {
    height: 174px !important;
}

@media screen and (min-width: 768px) {

    .navbar-default .navbar-nav li+li {
        border: none;
    }

    .hero-area {
        .hero-area-cont {
            h2 {
                font-size: 40px;
            }
            h5 {
                font-size: 20px;
            }
        }
    }

    /*
            OUR PROCESS
    */
    .fun-facts-area h1 {
        font-size: 40px;
    }

    .our-process-steps {
        p {
            font-size: 16px;
        }
        h2 {
            font-size: 20px;
        }

    }

    .paragraphs {
        font-size: 16px;
    }

    /*
            GET IN TOUCH
    */
    .contact-us-area {
        h2 {
            font-size: 40px;
        }
        h3 {
            font-size: 16px;
        }
        .message-form {
            .btn {
                width: initial;
            }
        }
    }

}

@media screen and (min-width: 992px) {
    .paragraphs {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 1024px) {

    .hero-area {
        .hero-area-cont {
            h2 {
                font-size: 60px;
            }
            h5 {
                font-size: 25px;
            }
        }
    }

    /*
            OUR SERVICES
    */
    .services-area {
        h2 {
            font-size: 48px;
        }
        p {
            font-size: 16px;
        }
    }

    /*
            OUR PROCESS
    */
    .fun-facts-area h1 {
        font-size: 48px;
    }

    .our-process-steps {
        .counter {
            span {
                top: 1px;
            }
        }
        p {
            font-size: 20px;
        }
        h2 {
            font-size: 24px;
        }
    }

    /*
            GET IN TOUCH
    */
    .contact-us-area {
        h2 {
            font-size: 48px;
        }
        h3 {
            font-size: 14px;
        }
    }

}

@media screen and (min-width: 1200px) {

    /*
            OUR SERVICES
    */
    .services-area {
        p {
            font-size: 16.5px;
        }
    }

    /*
        OUR CLIENTS
    */
    .clients-area {
        .slick-track {
            .slick-slide {
                img {
                    width: 235px;
                }
            }
        }
    }

}

@media screen and (min-width: 1307px) {
    .hero-area {
        .hero-area-cont {
            h2 {
                font-size: 70px;
            }
            h5 {
                font-size: 30px;
            }
        }
    }
}

@media screen and (min-width: 1722px) {
    .hero-area-cont {
        height: 100vh;
    }
}

/* Our Process View on mobile */
@media screen and (min-width:320px) and (max-width:414px){
    .fun-facts-area {
        background:$black-bg;
    }
}