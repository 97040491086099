.timeline-area {
  .row {
    &.timeline-items {
      margin-top: initial;
    }
  }
  .timeline-items {
    .timeline-item {
      display: flex;
      justify-content: center;
      align-items: center;
      &.row > [class*="col-"] {
        padding-bottom: 30px;
      }
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      background-color: #ffffff;
      text-align: left;
      img {
        height: 100px;
        width: 100px;
        object-fit: scale-down;
      }
      h2 {
        margin-bottom: 5px;
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        font-size: 20px;
      }
      p {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .timeline-area {
    .timeline-items {
      .timeline-item {
        h2 {
          font-size: 25px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
}
