.main-footer {
  background-color: #0f0f0f;
  .sub-footer {
    background-color: #0f0f0f;
  }
  .footer-widgets {
    .widget {
      .social-icons__item {
        .fa {
          color: #ccc;
          background-color: rgba(204, 204, 204, 0.1);
        }
      }
    }
  }
}

.list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

.copyright {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #cccccc;
}
