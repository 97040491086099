.timeline-area {
    background-color: #fafafa;
    .row {
        padding-left: 15px;
        padding-right: 15px;
        &.timeline-items {
            margin-top: initial;
        }
    }
}

.whiteBG {
    background-color: #ffffff;
}

.line-cont {
    .col-md-12 {
        padding-top: 0;
    }
    .line {
        position: absolute;
        left: 80px;
        height: 50px;
        width: 1px;
        background-color: #6ac9c8;
    }
}

@media screen and (min-width: 768px) {

    .timeline-area {
        .row {
            margin-bottom: 20px;
        }
    }

    .line-cont {
        .line {
            left: 320px;
            height: 55px;
            margin-top: -10px;
        }
    }

}

@media screen and (min-width: 992px) {

    .timeline-area {
        .row {
            margin-bottom: 30px;
        }
    }

    .line-cont {
        .line {
            left: 400px;
            height: 60px;
            margin-top: -30px;
        }
    }

}

@media screen and (min-width: 1200px) {

    .line-cont {
        .line {
            left: 460px;
        }
    }

}