.meet-our-team {
  background: #fafafa;
  .team-members {
    .single-member {
      .single-member-header {
        img {
          width: 100%;
        }
        div.placeholder {
          background-color: lightgray;
          min-height: 310px;
          min-width: 255px;
          width: 100%;
          height: 100%;
        }
        .overlay {
          height: 100%;
        }
      }
    }
    .member-info {
      h2 {
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        font-size: 25px;
        color: white;
      }
      p {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: white;
      }
    }
  }
}

.well {
  background: #fff;
  h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
    text-transform: uppercase;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
  }
}

.member-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
