.meet-our-team {
  h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: #000000;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    line-height: 1.5;
  }
  .card {
    padding: 19px;
  }
}

@media screen and (min-width: 768px) {
  .meet-our-team {
    h2 {
      font-size: 30px;
    }
    p {
      font-size: 16px;
    }
  }
}
