.additional-services {
  background-color: #fafafa;
  h2 {
    padding-top: 30px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 20px;
    color: #000000;
  }
}

@media screen and (min-width: 768px) {
  .additional-services {
    h2 {
      font-size: 23px;
    }
  }
}

@media screen and (min-width: 992px) {
  .additional-services {
    h2 {
      font-size: 23px;
    }
  }
}
