
.our-strategy-item {
    background: #fafafa;
    .row {
        margin-top: initial;
        margin-bottom: initial;
        & > [class*="col-"] {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    .card{
        background:#fff;
        min-height: 150px;
        margin-bottom: 0;
        padding: 19px;
        h2 {
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 20px;
            color: #000000;
            text-transform: uppercase;
        }
        p {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.5;
        }
    }
}

