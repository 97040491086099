.navbar {
  .navbar-brand img {
    cursor: pointer;
  }

  .navbar-nav > a,
  .navbar-nav > a:focus,
  .navbar-nav > .nav-item > a,
  .navbar-nav > .nav-item > a:focus {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    text-transform: initial;
    color: #000000;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 1rem;
  }

  .navbar-nav li.signup a,
  .navbar-nav li.signup a.active {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    background-color: #6ac9c8;
    &:hover {
      background-color: #4aca9d;
    }
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    border-color: #4aca9d;
  }

  .dropdown-item:hover .icon-bar,
  .dropdown-item:focus .icon-bar {
    background-color: #4aca9d;
  }

  .navbar-nav li.signup a:hover,
  .navbar-nav li.signup a:focus {
    background-color: #4aca9d;
  }
  .navbar-nav > .nav-item > a:hover {
    color: #6ac9c8;
  }

  .navbar-nav .nav-item.active a,
  .navbar-nav > .nav-item > a:hover,
  .navbar-nav > .show > a,
  .navbar-nav > .show > a:focus,
  .navbar-nav > .show > a:hover,
  .navbar-nav > .active > a,
  .navbar-nav > .active > a:hover,
  .navbar-nav > .active > a:focus {
    color: #6ac9c8;
  }

  .navbar-nav .show .dropdown-menu > li > a {
    color: #000000;
  }
}

.active-link {
  color: #6ac9c8 !important;
}

.navbar-brand {
  img {
    &:hover {
      -ms-opacity: 0.5;
      -moz-opacity: 0.5;
      -webkit-opacity: 0.5;
      opacity: 0.5;

      -ms-transition: 300ms linear;
      -moz-transition: 300ms linear;
      -webkit-transition: 300ms linear;
      transition: 300ms linear;
    }
  }
}

.hide-for-xs {
  display: none;
}

.dropdown {
  .dropdown-menu {
    font-size: 0.9rem;
    a {
      font-family: "Roboto", sans-serif !important;
      font-weight: 400 !important;
      text-align: center;
    }
    a.dropdown-item.active {
      color: #6ac9c8;
    }
    a.dropdown-item:focus,
    a.dropdown-item:hover,
    a.dropdown-item.active {
      background-color: #e8e8e8;
      background-image: -webkit-linear-gradient(top, #f5f5f5, #e8e8e8);
      background-image: -o-linear-gradient(top, #f5f5f5 0, #e8e8e8 100%);
      background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#f5f5f5),
        to(#e8e8e8)
      );
      background-image: linear-gradient(180deg, #f5f5f5 0, #e8e8e8);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fff5f5f5",endColorstr="#ffe8e8e8",GradientType=0);
      background-repeat: repeat-x;
    }
  }
}

@media screen and (min-width: 768px) {
  .hide-for-small {
    display: none;
  }

  .hide-for-xs {
    display: initial;
  }

  .hide-logo {
    display: none !important;
  }

  .show-logo {
    display: initial !important;
  }

  .dropdown {
    .dropdown-menu {
      background-color: #ffffff;
      border-radius: 8px !important;
      // border-top-left-radius: 10px !important;
      // border-top-right-radius: 10px !important;
      a {
        padding: 10px 10px !important;
        text-align: center;
      }
    }
  }
}
