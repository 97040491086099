.additional-services {
  .service-item {
    .service-content {
      h2 {
        margin-bottom: 0;
        padding-top: 0;
        font-family: "Roboto", sans-serif;
        font-weight: 600;
        font-size: 18px;
      }
      i {
        font-size: 40px;
      }
      p {
        margin-top: 5px;
        margin-bottom: 29px;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .additional-services {
    .service-item {
      .service-single {
        padding: 2px;
        .service-content {
          h2 {
            margin-bottom: 0;
            padding-top: 10px;
            font-family: "Roboto", sans-serif;
            font-weight: 700;
            font-size: 16px;
          }
          img {
            height: 80px;
            width: 160px;
          }
          i {
            padding-bottom: 10px;
            font-size: 40px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .additional-services {
    .service-item {
      .service-content {
        h2 {
          font-size: 16px;
        }
        i {
          padding-bottom: 10px;
        }
      }
      .service-single {
        padding: 5px;
      }
    }
  }
}
