.banner {
  padding-top: 200px;
  padding-bottom: 200px;
  background-color: #fafafa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #ffffff;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
  }
}

@media screen and (min-width: 768px) {
  .banner {
    h2 {
      font-size: 35px;
    }
    p {
      font-size: 16px;
      line-height: 1.5;
    }
  }
}

@media screen and (min-width: 992px) {
  .banner {
    h2 {
      font-size: 48px;
    }
    p {
      font-size: 18px;
    }
  }
}
