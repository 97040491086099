.our-process-item {
  background: #fafafa;
  .row > [class*="col-"] {
    padding-top: 0;
    margin-top: 15px;
  }
  // margin-bottom: 60px;
  img {
    padding: 15px;
  }
  h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    line-height: 1.5;
  }
}

.hide-for-md {
  display: none;
}

@media screen and (min-width: 768px) {
  .our-process-item {
    h2 {
      font-size: 23px;
    }
    p {
      font-size: 16px;
      line-height: 1.5;
    }
    img {
      padding: 30px;
    }
  }
}

@media screen and (min-width: 992px) {
  .our-process-item {
    padding-top: 30px;
    .row {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    h2 {
      font-size: 25px;
    }
  }

  .hide-for-md {
    display: initial;
  }

  .show-for-md {
    display: none;
  }
}
