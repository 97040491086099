.our-development-item {
  background: #fafafa;
  .row {
    margin-top: initial;
    margin-bottom: initial;
    & > [class*="col-"] {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  .card {
    background: #fff;
    min-height: 240px;
    margin-bottom: 0;
    padding: 19px;
    h2 {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      font-size: 20px;
      color: #000000;
      text-transform: uppercase;
    }
    p {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
    }
  }
}
.card-fixed {
  min-height: 150px !important;
}

@media screen and (min-width: 768px) {
  .our-design-item {
    h2 {
      font-size: 23px;
    }
    p {
      font-size: 16px;
      line-height: 1.5;
    }
  }
}

@media screen and (min-width: 992px) {
  .row {
    &.service-item {
      .col-md-2 {
        width: 20%;
      }
    }
  }
  .development-service {
    display: flex;
    justify-content: space-between;
  }
}
