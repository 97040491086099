.our-process-info {
    p {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        line-height: 1.5;
        color: #000000;
    }
}

@media screen and (min-width: 768px) {

    .our-process-info {
        p {
            font-size: 16px;
            line-height: 1.5;
        }
    }

}