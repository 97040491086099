.service-single {
  padding-top: 20px !important;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  img {
    width: 80%;
  }
  // min-height: 535px;
}

.services-one {
  .service-single {
    margin-top: 0;
    padding-top: 10px;
  }
}

.service-single.with-min {
  .service-content {
    min-height: 400px;
    p {
      min-height: 65px;
    }

    .blank-item {
      min-height: 60px;
      margin-top: 10px;
    }
  }
}

.service-single {
  transition: all 0.2s ease-in-out;
  .service-content {
    h2 {
      margin-top: 20px;
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      font-size: 18px;
      color: #000000;
    }
    p {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 14px !important;
      line-height: 1.5;
      color: #000000;
    }
    i {
      margin-top: 10px;
      font-size: 50px;
      color: #cccccc;
      &:hover {
        color: #6ac9c8;
        -ms-transition: 300ms linear;
        -moz-transition: 300ms linear;
        -webkit-transition: 300ms linear;
        transition: 300ms linear;
      }
    }
  }
}

.service-single:hover {
  transform: scale(1.03, 1.03);
}

@media screen and (min-width: 768px) {
  .services-area {
    h2 {
      font-size: 40px;
      text-align: center;
    }
    p {
      font-size: 14px;
      text-align: left;
    }
  }

  .service-single {
    img {
      padding: 0 20px;
      height: 300px;
      width: 300px;
      object-fit: fill;
    }
    .service-content {
      h2 {
        margin-top: 0;
        font-size: 20px;
      }
      p {
        font-size: 14px;
        text-align: center;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .services-one {
    .service-single {
      padding: 21px;
      img {
        padding: 0 10px;
        width: 300px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .service-single {
    .service-content {
      h2 {
        font-size: 20px;
      }
      p {
        font-size: 15px;
      }
    }
  }
}
