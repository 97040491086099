/*
-----------------------------------------------------------------------
BeOne - One Page Parallax
-----------------------------------------------------------------------
    - Author         : EvenThemes
    - Author URI     : http://bit.ly/2sjMgHz
    - Email          : eventhemes.contact@gmail.com
-----------------------------------------------------------------------
TABLE OF CONTENTS
-------------------
  01. Typography
  02. Helper Classes
  03. Form Control
  04. Buttons
  05. Page Layout
  06. Animated Headline
  07. Preloader
  08. Back To Top
  09. Main Header Area
  10. Hero Area
  11. Help Area
  12. Two Cols Area
  13. Video Area
  14. Services Area
  15. Features Area
  16. Fact Counter Area
  17. Pricing Table Area
  18. Testimonial Area
  19. Call To Action Area
  20. Our Team Area
  21. Portfolio Area
  22. Faq Area
  23. Subscribe Area
  24. Our Skills Area
  25. Contact Us Area
  26. Blog Area
  27. Footer Area
-----------------------------------------------------------------------
*/

@charset "utf-8";

/* --------------------------------------------- */
/* -------------->>> TYPOGRAPHY  <<<------------ */
/* --------------------------------------------- */

html {
  margin: 0;
  padding: 0;
  height: 100%;
}
body {
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 1.8;
  background-color: #fff;
  color: #384047;
  font-weight: 600;
  position: relative;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  overflow-scrolling: touch;
  -webkit-font-kerning: auto;
  -moz-font-kerning: auto;
  font-kerning: auto;
  -o-font-kerning: auto;
}
body.preloader-active {
  overflow-y: hidden;
  -ms-touch-action: none;
}
.no-js body.preloader-active {
  overflow-y: auto;
  -ms-touch-action: auto;
}
* {
  font-family: inherit;
}

/* Bugfix: IE 10 Windows 8 and Windows Phone8 */

@-ms-viewport {
  width: device-width;
}
@-o-viewport {
  width: device-width;
}
@viewport {
  width: device-width;
}
body,
input,
textarea {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*:focus,
*:active,
a:link,
a:hover,
a:visited {
  text-decoration: none;
  outline: none !important;
  outline-offset: 0 !important;
}
a {
  color: #314555;
}
a:hover,
a:focus {
  color: #00AEFD;
  text-decoration: none;
}
a,
button,
[role="button"],
input[type="button"],
input[type="submit"],
input[type="reset"] {
  cursor: pointer;
}
a,
button,
.btn,
.form-control {
  text-decoration: none;
  -webkit-transition: all 400ms linear 0ms;
  -ms-transition: all 400ms linear 0ms;
  -o-transition: all 400ms linear 0ms;
  transition: all 400ms linear 0ms;
}
select,
button,
[role="button"],
input[type="button"],
input[type="submit"],
input[type="reset"] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Remove chrome yellow autofill */

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f7f7f7 inset;
}

/* Rurn off number spinners */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Reset search styling */

input[type="search"] {
  outline: 0;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  display: block;
  line-height: 1.7;
  font-weight: 600;
  margin: 0;
  text-rendering: optimizeLegibility;
}
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: "Dosis", Arial, sans-serif;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}
.h1 {
  font-size: 36px;
}
.h2 {
  font-size: 28px;
}
.h3 {
  font-size: 20px;
}
.h4 {
  font-size: 18px;
}
.h5 {
  font-size: 16px;
}
.h6 {
  font-size: 14px;
}
b,
strong,
.t-bold {
  font-weight: bold;
}
ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  border-radius: inherit;
  -ms-interpolation-mode: bicubic;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
img::selection,
img::-webkit-selection,
img::-moz-selection {
  background: transparent;
}
figure {
  display: block;
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: relative;
}
iframe {
  border: none;
  width: 100%;
}
video,
audio {
  width: 100%;
  height: auto;
}
section {
  position: relative;
}
label {
  font-weight: normal;
}
q {
  display: inline-block;
}

/* --------------------------------------------- */
/* ----------->>> HELPER CLASSES  <<<----------- */
/* --------------------------------------------- */
/* |----------------[ Font Size ]--------------| */

.font-10 {
  font-size: 10px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-13 {
  font-size: 13px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-15 {
  font-size: 15px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-22 {
  font-size: 22px !important;
}
.font-24 {
  font-size: 24px !important;
}
.font-26 {
  font-size: 26px !important;
}
.font-28 {
  font-size: 28px !important;
}
.font-30 {
  font-size: 30px !important;
}
.font-32 {
  font-size: 32px !important;
}
.font-34 {
  font-size: 34px !important;
}
.font-36 {
  font-size: 36px !important;
}
.font-38 {
  font-size: 38px !important;
}
.font-40 {
  font-size: 40px !important;
}
.font-45 {
  font-size: 45px !important;
}
.font-50 {
  font-size: 50px !important;
}
.font-55 {
  font-size: 55px !important;
}
.font-60 {
  font-size: 60px !important;
}

/* |------------[ Padding & Margin ]-----------| */

.p-0 {
  padding: 0 !important;
}
.p-5 {
  padding: 5px !important;
}
.p-10 {
  padding: 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-30 {
  padding: 30px !important;
}
.p-40 {
  padding: 40px !important;
}
.p-50 {
  padding: 50px !important;
}
.p-60 {
  padding: 60px !important;
}
@media (min-width: 768px) {
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-5 {
    padding: 5px !important;
  }
  .p-sm-10 {
    padding: 10px !important;
  }
  .p-sm-15 {
    padding: 15px !important;
  }
  .p-sm-20 {
    padding: 20px !important;
  }
  .p-sm-30 {
    padding: 25px !important;
  }
  .p-sm-40 {
    padding: 30px !important;
  }
}
@media (min-width: 992px) {
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-5 {
    padding: 5px !important;
  }
  .p-md-10 {
    padding: 10px !important;
  }
  .p-md-15 {
    padding: 15px !important;
  }
  .p-md-20 {
    padding: 20px !important;
  }
  .p-md-30 {
    padding: 25px !important;
  }
  .p-md-40 {
    padding: 30px !important;
  }
}
@media (min-width: 1200px) {
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-5 {
    padding: 5px !important;
  }
  .p-lg-10 {
    padding: 10px !important;
  }
  .p-lg-15 {
    padding: 15px !important;
  }
  .p-lg-20 {
    padding: 20px !important;
  }
  .p-lg-30 {
    padding: 25px !important;
  }
  .p-lg-40 {
    padding: 30px !important;
  }
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pt-40 {
  padding-top: 40px !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pt-60 {
  padding-top: 60px !important;
}
.pt-70 {
  padding-top: 70px !important;
}
.pt-80 {
  padding-top: 80px !important;
}
.pt-90 {
  padding-top: 90px !important;
}
.pt-100 {
  padding-top: 100px !important;
}
@media (min-width: 768px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-5 {
    padding-top: 5px !important;
  }
  .pt-sm-10 {
    padding-top: 10px !important;
  }
  .pt-sm-15 {
    padding-top: 15px !important;
  }
  .pt-sm-20 {
    padding-top: 20px !important;
  }
  .pt-sm-30 {
    padding-top: 30px !important;
  }
  .pt-sm-40 {
    padding-top: 40px !important;
  }
  .pt-sm-50 {
    padding-top: 50px !important;
  }
  .pt-sm-60 {
    padding-top: 60px !important;
  }
  .pt-sm-70 {
    padding-top: 70px !important;
  }
  .pt-sm-80 {
    padding-top: 80px !important;
  }
  .pt-sm-90 {
    padding-top: 90px !important;
  }
  .pt-sm-100 {
    padding-top: 100px !important;
  }
}
@media (min-width: 992px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-5 {
    padding-top: 5px !important;
  }
  .pt-md-10 {
    padding-top: 10px !important;
  }
  .pt-md-15 {
    padding-top: 15px !important;
  }
  .pt-md-20 {
    padding-top: 20px !important;
  }
  .pt-md-30 {
    padding-top: 30px !important;
  }
  .pt-md-40 {
    padding-top: 40px !important;
  }
  .pt-md-50 {
    padding-top: 50px !important;
  }
  .pt-md-60 {
    padding-top: 60px !important;
  }
  .pt-md-70 {
    padding-top: 70px !important;
  }
  .pt-md-80 {
    padding-top: 80px !important;
  }
  .pt-md-90 {
    padding-top: 90px !important;
  }
  .pt-md-100 {
    padding-top: 100px !important;
  }
}
@media (min-width: 1200px) {
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-5 {
    padding-top: 5px !important;
  }
  .pt-lg-10 {
    padding-top: 10px !important;
  }
  .pt-lg-15 {
    padding-top: 15px !important;
  }
  .pt-lg-20 {
    padding-top: 20px !important;
  }
  .pt-lg-30 {
    padding-top: 30px !important;
  }
  .pt-lg-40 {
    padding-top: 40px !important;
  }
  .pt-lg-50 {
    padding-top: 50px !important;
  }
  .pt-lg-60 {
    padding-top: 60px !important;
  }
  .pt-lg-70 {
    padding-top: 70px !important;
  }
  .pt-lg-80 {
    padding-top: 80px !important;
  }
  .pt-lg-90 {
    padding-top: 90px !important;
  }
  .pt-lg-100 {
    padding-top: 100px !important;
  }
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.pb-50 {
  padding-bottom: 50px !important;
}
.pb-60 {
  padding-bottom: 60px !important;
}
.pb-70 {
  padding-bottom: 70px !important;
}
.pb-80 {
  padding-bottom: 80px !important;
}
.pb-90 {
  padding-bottom: 90px !important;
}
.pb-100 {
  padding-bottom: 100px !important;
}
@media (min-width: 768px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-5 {
    padding-bottom: 5px !important;
  }
  .pb-sm-10 {
    padding-bottom: 10px !important;
  }
  .pb-sm-15 {
    padding-bottom: 15px !important;
  }
  .pb-sm-20 {
    padding-bottom: 20px !important;
  }
  .pb-sm-30 {
    padding-bottom: 30px !important;
  }
  .pb-sm-40 {
    padding-bottom: 40px !important;
  }
  .pb-sm-50 {
    padding-bottom: 50px !important;
  }
  .pb-sm-60 {
    padding-bottom: 60px !important;
  }
  .pb-sm-70 {
    padding-bottom: 70px !important;
  }
  .pb-sm-80 {
    padding-bottom: 80px !important;
  }
  .pb-sm-90 {
    padding-bottom: 90px !important;
  }
  .pb-sm-100 {
    padding-bottom: 100px !important;
  }
}
@media (min-width: 992px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-5 {
    padding-bottom: 5px !important;
  }
  .pb-md-10 {
    padding-bottom: 10px !important;
  }
  .pb-md-15 {
    padding-bottom: 15px !important;
  }
  .pb-md-20 {
    padding-bottom: 20px !important;
  }
  .pb-md-30 {
    padding-bottom: 30px !important;
  }
  .pb-md-40 {
    padding-bottom: 40px !important;
  }
  .pb-md-50 {
    padding-bottom: 50px !important;
  }
  .pb-md-60 {
    padding-bottom: 60px !important;
  }
  .pb-md-70 {
    padding-bottom: 70px !important;
  }
  .pb-md-80 {
    padding-bottom: 80px !important;
  }
  .pb-md-90 {
    padding-bottom: 90px !important;
  }
  .pb-md-100 {
    padding-bottom: 100px !important;
  }
}
@media (min-width: 1200px) {
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-5 {
    padding-bottom: 5px !important;
  }
  .pb-lg-10 {
    padding-bottom: 10px !important;
  }
  .pb-lg-15 {
    padding-bottom: 15px !important;
  }
  .pb-lg-20 {
    padding-bottom: 20px !important;
  }
  .pb-lg-30 {
    padding-bottom: 30px !important;
  }
  .pb-lg-40 {
    padding-bottom: 40px !important;
  }
  .pb-lg-50 {
    padding-bottom: 50px !important;
  }
  .pb-lg-60 {
    padding-bottom: 60px !important;
  }
  .pb-lg-70 {
    padding-bottom: 70px !important;
  }
  .pb-lg-80 {
    padding-bottom: 80px !important;
  }
  .pb-lg-90 {
    padding-bottom: 90px !important;
  }
  .pb-lg-100 {
    padding-bottom: 100px !important;
  }
}
.pl-0 {
  padding-left: 0 !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.pl-40 {
  padding-left: 40px !important;
}
.pl-50 {
  padding-left: 50px !important;
}
.pl-60 {
  padding-left: 60px !important;
}
.pl-70 {
  padding-left: 70px !important;
}
.pl-80 {
  padding-left: 80px !important;
}
.pl-90 {
  padding-left: 90px !important;
}
.pl-100 {
  padding-left: 100px !important;
}
@media (min-width: 768px) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .pl-sm-5 {
    padding-left: 5px !important;
  }
  .pl-sm-10 {
    padding-left: 10px !important;
  }
  .pl-sm-15 {
    padding-left: 15px !important;
  }
  .pl-sm-20 {
    padding-left: 20px !important;
  }
  .pl-sm-30 {
    padding-left: 30px !important;
  }
  .pl-sm-40 {
    padding-left: 40px !important;
  }
  .pl-sm-50 {
    padding-left: 50px !important;
  }
  .pl-sm-60 {
    padding-left: 60px !important;
  }
  .pl-sm-70 {
    padding-left: 70px !important;
  }
  .pl-sm-80 {
    padding-left: 80px !important;
  }
  .pl-sm-90 {
    padding-left: 90px !important;
  }
  .pl-sm-100 {
    padding-left: 100px !important;
  }
}
@media (min-width: 992px) {
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .pl-md-5 {
    padding-left: 5px !important;
  }
  .pl-md-10 {
    padding-left: 10px !important;
  }
  .pl-md-15 {
    padding-left: 15px !important;
  }
  .pl-md-20 {
    padding-left: 20px !important;
  }
  .pl-md-30 {
    padding-left: 30px !important;
  }
  .pl-md-40 {
    padding-left: 40px !important;
  }
  .pl-md-50 {
    padding-left: 50px !important;
  }
  .pl-md-60 {
    padding-left: 60px !important;
  }
  .pl-md-70 {
    padding-left: 70px !important;
  }
  .pl-md-80 {
    padding-left: 80px !important;
  }
  .pl-md-90 {
    padding-left: 90px !important;
  }
  .pl-md-100 {
    padding-left: 100px !important;
  }
}
@media (min-width: 1200px) {
  .pl-lg-0 {
    padding-left: 0 !important;
  }
  .pl-lg-5 {
    padding-left: 5px !important;
  }
  .pl-lg-10 {
    padding-left: 10px !important;
  }
  .pl-lg-15 {
    padding-left: 15px !important;
  }
  .pl-lg-20 {
    padding-left: 20px !important;
  }
  .pl-lg-30 {
    padding-left: 30px !important;
  }
  .pl-lg-40 {
    padding-left: 40px !important;
  }
  .pl-lg-50 {
    padding-left: 50px !important;
  }
  .pl-lg-60 {
    padding-left: 60px !important;
  }
  .pl-lg-70 {
    padding-left: 70px !important;
  }
  .pl-lg-80 {
    padding-left: 80px !important;
  }
  .pl-lg-90 {
    padding-left: 90px !important;
  }
  .pl-lg-100 {
    padding-left: 100px !important;
  }
}
.pr-0 {
  padding-right: 0 !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pr-40 {
  padding-right: 40px !important;
}
.pr-50 {
  padding-right: 50px !important;
}
.pr-60 {
  padding-right: 60px !important;
}
.pr-70 {
  padding-right: 70px !important;
}
.pr-80 {
  padding-right: 80px !important;
}
.pr-90 {
  padding-right: 90px !important;
}
.pr-100 {
  padding-right: 100px !important;
}
@media (min-width: 768px) {
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .pr-sm-5 {
    padding-right: 5px !important;
  }
  .pr-sm-10 {
    padding-right: 10px !important;
  }
  .pr-sm-15 {
    padding-right: 15px !important;
  }
  .pr-sm-20 {
    padding-right: 20px !important;
  }
  .pr-sm-30 {
    padding-right: 30px !important;
  }
  .pr-sm-40 {
    padding-right: 40px !important;
  }
  .pr-sm-50 {
    padding-right: 50px !important;
  }
  .pr-sm-60 {
    padding-right: 60px !important;
  }
  .pr-sm-70 {
    padding-right: 70px !important;
  }
  .pr-sm-80 {
    padding-right: 80px !important;
  }
  .pr-sm-90 {
    padding-right: 90px !important;
  }
  .pr-sm-100 {
    padding-right: 100px !important;
  }
}
@media (min-width: 992px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .pr-md-5 {
    padding-right: 5px !important;
  }
  .pr-md-10 {
    padding-right: 10px !important;
  }
  .pr-md-15 {
    padding-right: 15px !important;
  }
  .pr-md-20 {
    padding-right: 20px !important;
  }
  .pr-md-30 {
    padding-right: 30px !important;
  }
  .pr-md-40 {
    padding-right: 40px !important;
  }
  .pr-md-50 {
    padding-right: 50px !important;
  }
  .pr-md-60 {
    padding-right: 60px !important;
  }
  .pr-md-70 {
    padding-right: 70px !important;
  }
  .pr-md-80 {
    padding-right: 80px !important;
  }
  .pr-md-90 {
    padding-right: 90px !important;
  }
  .pr-md-100 {
    padding-right: 100px !important;
  }
}
@media (min-width: 1200px) {
  .pr-lg-0 {
    padding-right: 0 !important;
  }
  .pr-lg-5 {
    padding-right: 5px !important;
  }
  .pr-lg-10 {
    padding-right: 10px !important;
  }
  .pr-lg-15 {
    padding-right: 15px !important;
  }
  .pr-lg-20 {
    padding-right: 20px !important;
  }
  .pr-lg-30 {
    padding-right: 25px !important;
  }
  .pr-lg-40 {
    padding-right: 30px !important;
  }
}
.ptb-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.ptb-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.ptb-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.ptb-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.ptb-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.ptb-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.ptb-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.ptb-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.ptb-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.ptb-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}
.ptb-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}
.ptb-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}
.ptb-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}
@media (min-width: 768px) {
  .ptb-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .ptb-sm-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .ptb-sm-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .ptb-sm-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .ptb-sm-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .ptb-sm-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .ptb-sm-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .ptb-sm-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .ptb-sm-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .ptb-sm-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .ptb-sm-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .ptb-sm-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .ptb-sm-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
}
@media (min-width: 992px) {
  .ptb-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .ptb-md-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .ptb-md-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .ptb-md-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .ptb-md-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .ptb-md-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .ptb-md-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .ptb-md-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .ptb-md-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .ptb-md-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .ptb-md-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .ptb-md-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .ptb-md-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
}
@media (min-width: 1200px) {
  .ptb-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .ptb-lg-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .ptb-lg-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .ptb-lg-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .ptb-lg-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .ptb-lg-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .ptb-lg-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .ptb-lg-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .ptb-lg-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .ptb-lg-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .ptb-lg-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .ptb-lg-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .ptb-lg-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
}
.prl-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.prl-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.prl-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.prl-15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.prl-20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.prl-30 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}
.prl-40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}
@media (min-width: 768px) {
  .prl-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .prl-sm-5 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .prl-sm-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .prl-sm-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .prl-sm-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .prl-sm-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .prl-sm-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
}
@media (min-width: 992px) {
  .prl-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .prl-md-5 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .prl-md-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .prl-md-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .prl-md-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .prl-md-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .prl-md-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
}
@media (min-width: 1200px) {
  .prl-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .prl-lg-5 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .prl-lg-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .prl-lg-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .prl-lg-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .prl-lg-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .prl-lg-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
}
.m-0 {
  margin: 0 !important;
}
.m-5 {
  margin: 5px !important;
}
.m-10 {
  margin: 10px !important;
}
.m-15 {
  margin: 15px !important;
}
.m-20 {
  margin: 20px !important;
}
.m-30 {
  margin: 30px !important;
}
.m-40 {
  margin: 30px !important;
}
@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-5 {
    margin: 5px !important;
  }
  .m-sm-10 {
    margin: 10px !important;
  }
  .m-sm-15 {
    margin: 15px !important;
  }
  .m-sm-20 {
    margin: 20px !important;
  }
  .m-sm-30 {
    margin: 30px !important;
  }
  .m-sm-40 {
    margin: 30px !important;
  }
}
@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-5 {
    margin: 5px !important;
  }
  .m-md-10 {
    margin: 10px !important;
  }
  .m-md-15 {
    margin: 15px !important;
  }
  .m-md-20 {
    margin: 20px !important;
  }
  .m-md-30 {
    margin: 30px !important;
  }
  .m-md-40 {
    margin: 30px !important;
  }
}
@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-5 {
    margin: 5px !important;
  }
  .m-lg-10 {
    margin: 10px !important;
  }
  .m-lg-15 {
    margin: 15px !important;
  }
  .m-lg-20 {
    margin: 20px !important;
  }
  .m-lg-30 {
    margin: 30px !important;
  }
  .m-lg-40 {
    margin: 30px !important;
  }
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
@media (min-width: 768px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-5 {
    margin-top: 5px !important;
  }
  .mt-sm-10 {
    margin-top: 10px !important;
  }
  .mt-sm-15 {
    margin-top: 15px !important;
  }
  .mt-sm-20 {
    margin-top: 20px !important;
  }
  .mt-sm-30 {
    margin-top: 30px !important;
  }
  .mt-sm-40 {
    margin-top: 40px !important;
  }
}
@media (min-width: 992px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-5 {
    margin-top: 5px !important;
  }
  .mt-md-10 {
    margin-top: 10px !important;
  }
  .mt-md-15 {
    margin-top: 15px !important;
  }
  .mt-md-20 {
    margin-top: 20px !important;
  }
  .mt-md-30 {
    margin-top: 30px !important;
  }
  .mt-md-40 {
    margin-top: 40px !important;
  }
}
@media (min-width: 1200px) {
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-5 {
    margin-top: 5px !important;
  }
  .mt-lg-10 {
    margin-top: 10px !important;
  }
  .mt-lg-15 {
    margin-top: 15px !important;
  }
  .mt-lg-20 {
    margin-top: 20px !important;
  }
  .mt-lg-30 {
    margin-top: 30px !important;
  }
  .mt-lg-40 {
    margin-top: 40px !important;
  }
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
@media (min-width: 768px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-5 {
    margin-bottom: 5px !important;
  }
  .mb-sm-10 {
    margin-bottom: 10px !important;
  }
  .mb-sm-15 {
    margin-bottom: 15px !important;
  }
  .mb-sm-20 {
    margin-bottom: 20px !important;
  }
  .mb-sm-30 {
    margin-bottom: 30px !important;
  }
  .mb-sm-40 {
    margin-bottom: 40px !important;
  }
}
@media (min-width: 992px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-5 {
    margin-bottom: 5px !important;
  }
  .mb-md-10 {
    margin-bottom: 10px !important;
  }
  .mb-md-15 {
    margin-bottom: 15px !important;
  }
  .mb-md-20 {
    margin-bottom: 20px !important;
  }
  .mb-md-30 {
    margin-bottom: 30px !important;
  }
  .mb-md-40 {
    margin-bottom: 40px !important;
  }
}
@media (min-width: 1200px) {
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-5 {
    margin-bottom: 5px !important;
  }
  .mb-lg-10 {
    margin-bottom: 10px !important;
  }
  .mb-lg-15 {
    margin-bottom: 15px !important;
  }
  .mb-lg-20 {
    margin-bottom: 20px !important;
  }
  .mb-lg-30 {
    margin-bottom: 30px !important;
  }
  .mb-lg-40 {
    margin-bottom: 40px !important;
  }
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
@media (min-width: 768px) {
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .ml-sm-5 {
    margin-left: 5px !important;
  }
  .ml-sm-10 {
    margin-left: 10px !important;
  }
  .ml-sm-15 {
    margin-left: 15px !important;
  }
  .ml-sm-20 {
    margin-left: 20px !important;
  }
  .ml-sm-30 {
    margin-left: 30px !important;
  }
  .ml-sm-40 {
    margin-left: 40px !important;
  }
}
@media (min-width: 992px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .ml-md-5 {
    margin-left: 5px !important;
  }
  .ml-md-10 {
    margin-left: 10px !important;
  }
  .ml-md-15 {
    margin-left: 15px !important;
  }
  .ml-md-20 {
    margin-left: 20px !important;
  }
  .ml-md-30 {
    margin-left: 30px !important;
  }
  .ml-md-40 {
    margin-left: 40px !important;
  }
}
@media (min-width: 1200px) {
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .ml-lg-5 {
    margin-left: 5px !important;
  }
  .ml-lg-10 {
    margin-left: 10px !important;
  }
  .ml-lg-15 {
    margin-left: 15px !important;
  }
  .ml-lg-20 {
    margin-left: 20px !important;
  }
  .ml-lg-30 {
    margin-left: 30px !important;
  }
  .ml-lg-40 {
    margin-left: 40px !important;
  }
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
@media (min-width: 768px) {
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .mr-sm-5 {
    margin-right: 5px !important;
  }
  .mr-sm-10 {
    margin-right: 10px !important;
  }
  .mr-sm-15 {
    margin-right: 15px !important;
  }
  .mr-sm-20 {
    margin-right: 20px !important;
  }
  .mr-sm-30 {
    margin-right: 30px !important;
  }
  .mr-sm-40 {
    margin-right: 40px !important;
  }
}
@media (min-width: 992px) {
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .mr-md-5 {
    margin-right: 5px !important;
  }
  .mr-md-10 {
    margin-right: 10px !important;
  }
  .mr-md-15 {
    margin-right: 15px !important;
  }
  .mr-md-20 {
    margin-right: 20px !important;
  }
  .mr-md-30 {
    margin-right: 30px !important;
  }
  .mr-md-40 {
    margin-right: 40px !important;
  }
}
@media (min-width: 1200px) {
  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .mr-lg-5 {
    margin-right: 5px !important;
  }
  .mr-lg-10 {
    margin-right: 10px !important;
  }
  .mr-lg-15 {
    margin-right: 15px !important;
  }
  .mr-lg-20 {
    margin-right: 20px !important;
  }
  .mr-lg-30 {
    margin-right: 30px !important;
  }
  .mr-lg-40 {
    margin-right: 40px !important;
  }
}
.mtb-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.mtb-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.mtb-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.mtb-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.mtb-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.mtb-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.mtb-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.mtb-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.mtb-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
.mtb-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}
.mtb-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}
.mtb-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}
.mtb-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}
@media (min-width: 768px) {
  .mtb-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .mtb-sm-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .mtb-sm-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .mtb-sm-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .mtb-sm-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .mtb-sm-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .mtb-sm-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .mtb-sm-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .mtb-sm-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .mtb-sm-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .mtb-sm-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .mtb-sm-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .mtb-sm-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
}
@media (min-width: 992px) {
  .mtb-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .mtb-md-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .mtb-md-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .mtb-md-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .mtb-md-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .mtb-md-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .mtb-md-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .mtb-md-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .mtb-md-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .mtb-md-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .mtb-md-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .mtb-md-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .mtb-md-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
}
@media (min-width: 1200px) {
  .mtb-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .mtb-lg-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .mtb-lg-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .mtb-lg-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .mtb-lg-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .mtb-lg-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .mtb-lg-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .mtb-lg-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .mtb-lg-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .mtb-lg-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .mtb-lg-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .mtb-lg-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .mtb-lg-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
}
.mrl-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mrl-5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}
.mrl-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}
.mrl-15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}
.mrl-20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}
.mrl-30 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}
.mrl-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}
.mrl-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
@media (min-width: 768px) {
  .mrl-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mrl-sm-5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mrl-sm-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .mrl-sm-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mrl-sm-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mrl-sm-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mrl-sm-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mrl-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .mrl-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mrl-md-5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mrl-md-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .mrl-md-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mrl-md-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mrl-md-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mrl-md-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mrl-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .mrl-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mrl-lg-5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mrl-lg-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .mrl-lg-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mrl-lg-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mrl-lg-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mrl-lg-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mrl-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

/* |--------------[ Text Styles ]--------------| */

.color-theme {
  color: #00AEFD !important;
}
.color-green {
  color: #88c724 !important;
}
.color-pink {
  color: #FC3951 !important;
}
.color-purple {
  color: #9664fb !important;
}
.color-orange {
  color: #FFA91E !important;
}

.color-white {
  color: #fff !important;
}
.color-gray {
  color: #e9ebee !important;
}
.color-lighter {
  color: #F5F5F5 !important;
}
.color-light {
  color: #d4d4d4 !important;
}
.color-muted {
  color: #97a4ad !important;
}
.color-mid {
  color: #717f86 !important;
}
.color-dark {
  color: #4c5760 !important;
}
.color-darker {
  color: #384047 !important;
}
.color-inherit {
  color: inherit !important;
}
.t-italic {
  font-style: italic;
}
.t-uppercase {
  text-transform: uppercase;
}
.t-lowercase {
  text-transform: lowercase;
}
.t-no-decor {
  text-decoration: none;
}
.t-underline {
  text-decoration: underline !important;
}
.t-line-through {
  text-decoration: line-through;
}
.t-h-no-decor:hover {
  text-decoration: none;
}
.t-h-underline:hover {
  text-decoration: underline;
}
.t-h-line-through:hover {
  text-decoration: line-through;
}
.t-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.t-left,
.t-xs-left {
  text-align: left !important;
}
.t-right,
.t-xs-right {
  text-align: right !important;
}
.t-center,
.t-xs-center {
  text-align: center !important;
}
@media (min-width: 768px) {
  .t-sm-left {
    text-align: left !important;
  }
  .t-sm-right {
    text-align: right !important;
  }
  .t-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .t-md-left {
    text-align: left !important;
  }
  .t-md-right {
    text-align: right !important;
  }
  .t-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .t-lg-left {
    text-align: left !important;
  }
  .t-lg-right {
    text-align: right !important;
  }
  .t-lg-center {
    text-align: center !important;
  }
}
.valign-top {
  vertical-align: top !important;
}
.valign-bottom {
  vertical-align: bottom !important;
}
.valign-middle {
  vertical-align: middle !important;
}
.valign-baseline {
  vertical-align: baseline !important;
}
.valign-text-top {
  vertical-align: text-top !important;
}
.valign-text-bottom {
  vertical-align: text-bottom !important;
}
.valign-sub {
  vertical-align: sub !important;
}
.valign-super {
  vertical-align: super !important;
}
@media (min-width: 768px) {
  .valign-sm-top {
    vertical-align: top !important;
  }
  .valign-sm-bottom {
    vertical-align: bottom !important;
  }
  .valign-sm-middle {
    vertical-align: middle !important;
  }
  .valign-sm-baseline {
    vertical-align: baseline !important;
  }
  .valign-sm-text-top {
    vertical-align: text-top !important;
  }
  .valign-sm-text-bottom {
    vertical-align: text-bottom !important;
  }
  .valign-sm-sub {
    vertical-align: sub !important;
  }
  .valign-sm-super {
    vertical-align: super !important;
  }
}
@media (min-width: 992px) {
  .valign-md-top {
    vertical-align: top !important;
  }
  .valign-md-bottom {
    vertical-align: bottom !important;
  }
  .valign-md-middle {
    vertical-align: middle !important;
  }
  .valign-md-baseline {
    vertical-align: baseline !important;
  }
  .valign-md-text-top {
    vertical-align: text-top !important;
  }
  .valign-md-text-bottom {
    vertical-align: text-bottom !important;
  }
  .valign-md-sub {
    vertical-align: sub !important;
  }
  .valign-md-super {
    vertical-align: super !important;
  }
}
@media (min-width: 1200px) {
  .valign-lg-top {
    vertical-align: top !important;
  }
  .valign-lg-bottom {
    vertical-align: bottom !important;
  }
  .valign-lg-middle {
    vertical-align: middle !important;
  }
  .valign-lg-baseline {
    vertical-align: baseline !important;
  }
  .valign-lg-text-top {
    vertical-align: text-top !important;
  }
  .valign-lg-text-bottom {
    vertical-align: text-bottom !important;
  }
  .valign-lg-sub {
    vertical-align: sub !important;
  }
  .valign-lg-super {
    vertical-align: super !important;
  }
}

/* |--------------[ Quick floats ]-------------| */

.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
.float-center {
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 768px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .float-sm-center {
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (min-width: 992px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .float-md-center {
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .float-lg-center {
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

/* |-----------[ Overflow Styles ]-----------| */

.overflow-hidden,
.no-scroll {
  overflow: hidden !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.overflow-visible {
  overflow: visible !important;
}
@media (min-width: 768px) {
  .overflow-sm-hidden,
  .no-scroll-sm {
    overflow: hidden !important;
  }
  .overflow-sm-auto {
    overflow: auto !important;
  }
  .overflow-sm-scroll {
    overflow: scroll !important;
  }
  .overflow-sm-visible {
    overflow: visible !important;
  }
}
@media (min-width: 992px) {
  .overflow-md-hidden,
  .no-scroll-md {
    overflow: hidden !important;
  }
  .overflow-md-auto {
    overflow: auto !important;
  }
  .overflow-md-scroll {
    overflow: scroll !important;
  }
  .overflow-md-visible {
    overflow: visible !important;
  }
}
@media (min-width: 1200px) {
  .overflow-lg-hidden,
  .no-scroll-lg {
    overflow: hidden !important;
  }
  .overflow-lg-auto {
    overflow: auto !important;
  }
  .overflow-lg-scroll {
    overflow: scroll !important;
  }
  .overflow-lg-visible {
    overflow: visible !important;
  }
}

/* |--------------[ Background Colors ]--------------| */

.bg-theme {
  background-color: #00AEFD !important;
}
.bg-green {
  background-color: #88c724 !important;
}
.bg-pink {
  background-color: #FC3951 !important;
}
.bg-purple {
  background-color: #9664fb !important;
}
.bg-orange {
  background-color: #FFA91E !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-black {
  background-color: #000 !important;
}
.bg-overlay {
  background-color: #090f25;
}
.bg-gray {
  background-color: #f0f4f7 !important;
}
.bg-lighter {
  background-color: #f9fafa !important;
}
.bg-light {
  background-color: #eee !important;
}
.bg-mid {
  background-color: #eee !important;
}
.bg-dark {
  background-color: #ddd !important;
}
.bg-inherit {
  background-color: inherit !important;
}
.bg-transparent {
  background-color: transparent;
}

/* |-----------[ Background Styles ]-----------| */

.bg-cover,
*[data-bg-img] {
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.bg-contain {
  background-size: contain;
}
.bg-fixed {
  background-attachment: fixed;
}
.bg-cc {
  background-position: center center !important;
}
.bg-ct {
  background-position: center top !important;
}
.bg-cb {
  background-position: center bottom !important;
}
.bg-lc {
  background-position: left center !important;
}
.bg-lt {
  background-position: left top !important;
}
.bg-lb {
  background-position: left bottom !important;
}
.bg-rc {
  background-position: right center !important;
}
.bg-rt {
  background-position: right top !important;
}
.bg-rb {
  background-position: right bottom !important;
}
.no-bg {
  background: transparent none !important;
}

/* |------------[ Display Property ]-----------| */

.pos-r {
  position: relative !important;
}
.pos-a {
  position: absolute !important;
}
.pos-f {
  position: fixed !important;
  backface-visibility: hidden !important;
}
.pos-s {
  position: static !important;
}
.top-0 {
  top: 0 !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.right-0 {
  right: 0 !important;
}
.left-0 {
  left: 0 !important;
}
.top-5 {
  top: 5px !important;
}
.bottom-5 {
  bottom: 5px !important;
}
.right-5 {
  right: 5px !important;
}
.left-5 {
  left: 5px !important;
}
.top-10 {
  top: 10px !important;
}
.bottom-10 {
  bottom: 10px !important;
}
.right-10 {
  right: 10px !important;
}
.left-10 {
  left: 10px !important;
}
.top-15 {
  top: 15px !important;
}
.bottom-15 {
  bottom: 15px !important;
}
.right-15 {
  right: 15px !important;
}
.left-15 {
  left: 15px !important;
}
.top-20 {
  top: 20px !important;
}
.bottom-20 {
  bottom: 20px !important;
}
.right-20 {
  right: 20px !important;
}
.left-20 {
  left: 20px !important;
}
.is-block {
  display: block !important;
}
.is-inline-block {
  display: inline-block !important;
}
.is-inline {
  display: inline !important;
}
.is-hidden {
  display: none !important;
}
.is-table {
  display: table !important;
}
.is-table-cell {
  display: table-cell !important;
}
.is-table-row {
  display: table-row !important;
}
.no-js .is-hidden--no-js {
  display: none !important;
}
.js .is-hidden--js {
  display: none !important;
}
.pos-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.pos-tb-center {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.pos-rl-center {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  height: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .pos-sm-a {
    position: relative !important;
  }
  .pos-sm-r {
    position: absolute !important;
  }
  .pos-sm-f {
    position: fixed !important;
    backface-visibility: hidden !important;
  }
  .pos-sm-s {
    position: static !important;
  }
  .top-sm-0 {
    top: 0 !important;
  }
  .bottom-sm-0 {
    bottom: 0 !important;
  }
  .right-sm-0 {
    right: 0 !important;
  }
  .left-sm-0 {
    left: 0 !important;
  }
  .top-sm-5 {
    top: 5px !important;
  }
  .bottom-sm-5 {
    bottom: 5px !important;
  }
  .right-sm-5 {
    right: 5px !important;
  }
  .left-sm-5 {
    left: 5px !important;
  }
  .top-sm-10 {
    top: 10px !important;
  }
  .bottom-sm-10 {
    bottom: 10px !important;
  }
  .right-sm-10 {
    right: 10px !important;
  }
  .left-sm-10 {
    left: 10px !important;
  }
  .top-sm-15 {
    top: 15px !important;
  }
  .bottom-sm-15 {
    bottom: 15px !important;
  }
  .right-sm-15 {
    right: 15px !important;
  }
  .left-sm-15 {
    left: 15px !important;
  }
  .top-sm-20 {
    top: 20px !important;
  }
  .bottom-sm-20 {
    bottom: 20px !important;
  }
  .right-sm-20 {
    right: 20px !important;
  }
  .left-sm-20 {
    left: 20px !important;
  }
  .is-block-sm {
    display: block !important;
  }
  .is-inline-block-sm {
    display: inline-block !important;
  }
  .is-inline-sm {
    display: inline !important;
  }
  .is-table-sm {
    display: table !important;
  }
  .is-table-cell-sm {
    display: table-cell !important;
  }
  .is-table-row-sm {
    display: table-row !important;
  }
}
@media (min-width: 992px) {
  .pos-md-a {
    position: relative !important;
  }
  .pos-md-r {
    position: absolute !important;
  }
  .pos-md-f {
    position: fixed !important;
    backface-visibility: hidden !important;
  }
  .pos-md-s {
    position: static !important;
  }
  .top-md-0 {
    top: 0 !important;
  }
  .bottom-md-0 {
    bottom: 0 !important;
  }
  .right-md-0 {
    right: 0 !important;
  }
  .left-md-0 {
    left: 0 !important;
  }
  .top-md-5 {
    top: 5px !important;
  }
  .bottom-md-5 {
    bottom: 5px !important;
  }
  .right-md-5 {
    right: 5px !important;
  }
  .left-md-5 {
    left: 5px !important;
  }
  .top-md-10 {
    top: 10px !important;
  }
  .bottom-md-10 {
    bottom: 10px !important;
  }
  .right-md-10 {
    right: 10px !important;
  }
  .left-md-10 {
    left: 10px !important;
  }
  .top-md-15 {
    top: 15px !important;
  }
  .bottom-md-15 {
    bottom: 15px !important;
  }
  .right-md-15 {
    right: 15px !important;
  }
  .left-md-15 {
    left: 15px !important;
  }
  .top-md-20 {
    top: 20px !important;
  }
  .bottom-md-20 {
    bottom: 20px !important;
  }
  .right-md-20 {
    right: 20px !important;
  }
  .left-md-20 {
    left: 20px !important;
  }
  .is-block-md {
    display: block !important;
  }
  .is-inline-block-md {
    display: inline-block !important;
  }
  .is-inline-md {
    display: inline !important;
  }
  .is-table-md {
    display: table !important;
  }
  .is-table-cell-md {
    display: table-cell !important;
  }
  .is-table-row-md {
    display: table-row !important;
  }
}
@media (min-width: 1200px) {
  .pos-lg-a {
    position: relative !important;
  }
  .pos-lg-r {
    position: absolute !important;
  }
  .pos-lg-f {
    position: fixed !important;
    backface-visibility: hidden !important;
  }
  .pos-lg-s {
    position: static !important;
  }
  .top-lg-0 {
    top: 0 !important;
  }
  .bottom-lg-0 {
    bottom: 0 !important;
  }
  .right-lg-0 {
    right: 0 !important;
  }
  .left-lg-0 {
    left: 0 !important;
  }
  .top-lg-5 {
    top: 5px !important;
  }
  .bottom-lg-5 {
    bottom: 5px !important;
  }
  .right-lg-5 {
    right: 5px !important;
  }
  .left-lg-5 {
    left: 5px !important;
  }
  .top-lg-10 {
    top: 10px !important;
  }
  .bottom-lg-10 {
    bottom: 10px !important;
  }
  .right-lg-10 {
    right: 10px !important;
  }
  .left-lg-10 {
    left: 10px !important;
  }
  .top-lg-15 {
    top: 15px !important;
  }
  .bottom-lg-15 {
    bottom: 15px !important;
  }
  .right-lg-15 {
    right: 15px !important;
  }
  .left-lg-15 {
    left: 15px !important;
  }
  .top-lg-20 {
    top: 20px !important;
  }
  .bottom-lg-20 {
    bottom: 20px !important;
  }
  .right-lg-20 {
    right: 20px !important;
  }
  .left-lg-20 {
    left: 20px !important;
  }
  .is-block-lg {
    display: block !important;
  }
  .is-inline-block-lg {
    display: inline-block !important;
  }
  .is-inline-lg {
    display: inline !important;
  }
  .is-table-lg {
    display: table !important;
  }
  .is-table-cell-lg {
    display: table-cell !important;
  }
  .is-table-row-lg {
    display: table-row !important;
  }
}
@media only screen and (max-width: 767px) {
  .is-hidden-xs,
  .is-hidden-xs-down {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .is-hidden-sm-up {
    display: none !important;
  }
}
@media only screen and (max-width: 991px) {
  .is-hidden-sm-down {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .is-hidden-sm {
    display: none !important;
  }
}
@media only screen and (min-width: 992px) {
  .is-hidden-md-up {
    display: none !important;
  }
}
@media only screen and (max-width: 1199px) {
  .is-hidden-md-down {
    display: none !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .is-hidden-md {
    display: none !important;
  }
}
@media only screen and (min-width: 1200px) {
  .is-hidden-lg,
  .is-hidden-lg-up {
    display: none !important;
  }
}
.is-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.flex-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 !important;
  flex: 1 !important;
}
.flex-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex-items-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.flex-items-bottom {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

/* |-----------[ Width/Height ]----------| */

.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.w-auto,
.w-xs-auto {
  width: auto !important;
}
.w-full,
.w-xs-full {
  width: 100% !important;
}
.w-fit,
.w-xs-fit {
  max-width: 100% !important;
}
.h-auto,
.h-xs-auto {
  height: auto !important;
}
.h-full,
.h-xs-full {
  height: 100% !important;
}
.h-full-screen,
.h-xs-full-screen {
  min-height: 100vh !important;
}
@media (min-width: 768px) {
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-full {
    width: 100% !important;
  }
  .w-sm-fit {
    max-width: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .h-sm-full {
    height: 100% !important;
  }
  .h-sm-full-screen {
    min-height: 100vh !important;
  }
}
@media (min-width: 992px) {
  .w-md-auto {
    width: auto !important;
  }
  .w-md-full {
    width: 100% !important;
  }
  .w-md-fit {
    max-width: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .h-md-full {
    height: 100% !important;
  }
  .h-md-full-screen {
    min-height: 100vh !important;
  }
}
@media (min-width: 1200px) {
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-full {
    width: 100% !important;
  }
  .w-lg-fit {
    max-width: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .h-lg-full {
    height: 100% !important;
  }
  .h-lg-full-screen {
    min-height: 100vh !important;
  }
}

/* |---------------[ Border ]---------------| */

.border-theme {
  border-color: #00AEFD !important;
}
.border-green {
  border-color: #88c724 !important;
}
.border-pink {
  border-color: #FC3951 !important;
}
.border-purple {
  border-color: #9664fb !important;
}
.border-orange {
  border-color: #FFA91E !important;
}
.circle,
[class*="radius-"],
[class*="rounded"] {
  overflow: hidden;
}
.radius-0,
.square {
  border-radius: 0;
}
.radius-1 {
  border-radius: 1px;
}
.radius-2 {
  border-radius: 2px;
}
.radius-3 {
  border-radius: 3px;
}
.radius-4 {
  border-radius: 4px;
}
.radius-5 {
  border-radius: 5px;
}
.radius-6 {
  border-radius: 6px;
}
.radius-7 {
  border-radius: 7px;
}
.radius-8 {
  border-radius: 8px;
}
.radius-9 {
  border-radius: 9px;
}
.radius-10 {
  border-radius: 10px;
}
.circle {
  border-radius: 9999px !important;
}

/* |-------------[ Opacity ]------------| */

.alpha-0 {
  opacity: 0;
}
.alpha-10 {
  opacity: 0.1;
}
.alpha-20 {
  opacity: 0.2;
}
.alpha-30 {
  opacity: 0.3;
}
.alpha-40 {
  opacity: 0.4;
}
.alpha-50 {
  opacity: 0.5;
}
.alpha-60 {
  opacity: 0.6;
}
.alpha-70 {
  opacity: 0.7;
}
.alpha-75 {
  opacity: 0.75;
}
.alpha-80 {
  opacity: 0.8;
}
.alpha-90 {
  opacity: 0.9;
}
.alpha-1,
.alpha-100,
{
  opacity: 1;
}
.zi--1 {
  z-index: -1;
}
.zi-0 {
  z-index: 1;
}
.zi-2 {
  z-index: 2;
}
.zi-3 {
  z-index: 3;
}
.zi-4 {
  z-index: 4;
}
.zi-5 {
  z-index: 5;
}
.zi-99 {
  z-index: 99;
}
.wi-999 {
  z-index: 999;
}

/* --------------------------------------------- */
/* ------------->>> FORM CONTROL  <<<------------ */
/* --------------------------------------------- */

.form-control {
  height: 40px;
  padding: 8px 12px;
  font-size: 13px;
  line-height: 20px;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  vertical-align: middle;
  -webkit-transition: all ease-in-out 150ms;
  -ms-transition: all ease-in-out 150ms;
  -o-transition: all ease-in-out 150ms;
  transition: all ease-in-out 150ms;
}
.form-control::-webkit-input-placeholder {
  color: #999;
}
.form-control:-moz-placeholder {
  color: #999;
}
.form-control::-moz-placeholder {
  color: #999;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control:focus {
  border-color: #7bc144;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.form-control.no-border,
.form-control.no-border:focus {
  border-color: transparent;
}
select.form-control {
  height: 40px;
  line-height: 20px;
  background-repeat: no-repeat;
  background-position: right 12px center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #f1f1f1;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 40px;
  }
  input[type="date"].form-control.input-lg,
  input[type="time"].form-control.input-lg,
  input[type="datetime-local"].form-control.input-lg,
  input[type="month"].form-control.input-lg,
  .input-group-lg input[type="date"].form-control,
  .input-group-lg input[type="time"].form-control,
  .input-group-lg input[type="datetime-local"].form-control,
  .input-group-lg input[type="month"].form-control {
    line-height: 46px;
  }
  input[type="date"].form-control.input-sm,
  input[type="time"].form-control.input-sm,
  input[type="datetime-local"].form-control.input-sm,
  input[type="month"].form-control.input-sm,
  .input-group-sm input[type="date"].form-control,
  .input-group-sm input[type="time"].form-control,
  .input-group-sm input[type="datetime-local"].form-control,
  .input-group-sm input[type="month"].form-control {
    line-height: 34px;
  }
}
.form-group {
  margin-bottom: 20px;
}
textarea.form-control {
  line-height: 18px;
  resize: none;
}
.form-control-static {
  min-height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 0;
}
.form-control-static.input-lg,
.form-control-static.input-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-group-lg .input-lg,
.form-group-lg .form-control,
.form-group-lg select.form-control,
.form-group-lg .form-control-static,
.input-lg,
select.input-lg {
  height: 46px;
  padding: 11px 14px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 3px;
}
.form-group-sm .form-control,
.form-group-sm .form-control-static,
.input-sm {
  height: 34px;
  padding: 4px 10px;
  font-size: 13px;
  line-height: 20px;
  border-radius: 3px;
}
.form-group-sm select.form-control,
select.input-sm {
  height: 34px;
  line-height: 32px;
  padding: 0 10px;
}
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  margin-left: -2px;
}
.field {
  display: block;
  position: relative;
}
.field select {
  display: none;
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
  z-index: -999;
}
.field select + input[disabled] {
  background-color: #fff;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.field .select-clone {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 3px;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 3px;
  z-index: 999;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
}
.field .select-clone li {
  display: block;
  padding: 8px 15px;
  text-align: left;
  color: #555;
  cursor: pointer;
}
.field .select-clone li+li {
  border-top: 1px solid #eee;
}
.field .fa {
  position: absolute;
  right: 12px;
  top: 15px;
  color: #ccc;
}

/* --------------------------------------------- */
/* --------------->>> BUTTONS  <<<-------------- */
/* --------------------------------------------- */

.btn {
  height: 40px;
  padding: 8px 20px;
  border: 2px solid transparent;
  font-size: 14px;
  line-height: 20px;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  font-weight: bold;
  overflow: hidden;
}
.btn:active,
.btn.active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  opacity: .8;
}
.btn.btn-rounded {
  border-radius: 999px !important;
  position: relative;
  padding-right: 50px;
}
.btn-rounded.btn-lg {
  padding-right: 54px;
}
.btn.btn-rounded:after {
  content: "\f105";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  width: 36px;
  height: 36px;
  line-height: 36px;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  text-align: center;
}
.btn-lg.btn-rounded:after {
  width: 42px;
  height: 42px;
  line-height: 42px;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  text-align: center;
}
.btn-group-xlg > .btn,
.btn-xlg {
  height: 52px;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
}
.btn-group-lg > .btn,
.btn-lg {
  height: 46px;
  padding: 11px 30px;
  font-size: 15px;
  line-height: 20px;
  border-radius: 3px;
}
.btn-group-sm > .btn,
.btn-sm {
  height: 34px;
  padding: 5px 15px;
  font-size: 12px;
  line-height: 20px;
  border-radius: 3px;
}
.btn-group-xs > .btn,
.btn-xs {
  height: 28px;
  padding: 2px 10px;
  font-size: 10px;
  line-height: 20px;
  border-radius: 3px;
}
.btn.btn-link {
  height: auto;
  padding: 0;
  border: none;
}

/* |-------------[ Default Button ]------------| */

.btn {
  background-color: #00AEFD;
  color: #fff;
}
.btn.btn-link,
.btn.btn-o {
  color: #00aefd;
  background-color: transparent;
}
.btn.btn-o {
  border-color: #00AEFD;
}
.btn:hover,
.btn.hover,
.btn:focus,
.btn.focus,
.btn:active,
.btn.active {
  color: #fff;
  background-color: #019be0;
  border-color: #019be0;
}
.btn.btn-link:hover,
.btn.btn-link.hover,
.btn.btn-link:focus,
.btn.btn-link.focus,
.btn.btn-link:active,
.btn.btn-link.active {
  background-color: transparent !important;
  color: #019be0;
}
.btn.btn-o:hover,
.btn.btn-o.hover,
.btn.btn-o:focus,
.btn.btn-o.focus,
.btn.btn-o:active,
.btn.btn-o.active {
  border-color: #019be0;
}

/* |--------------[ Orange Button ]--------------| */

.btn.btn-orange {
  background-color: #FFA91E;
  color: #fff;
}
.btn.btn-orange.btn-link,
.btn.btn-orange.btn-o {
  color: #FFA91E;
  background-color: transparent;
}
.btn.btn-orange.btn-o {
  border-color: #FFA91E;
}
.btn.btn-orange:hover,
.btn.btn-orange.hover,
.btn.btn-orange:focus,
.btn.btn-orange.focus,
.btn.btn-orange:active,
.btn.btn-orange.active {
  color: #fff;
  background-color: #ef9e1c;
  border-color: #ef9e1c;
}
.btn.btn-orange.btn-link:hover,
.btn.btn-orange.btn-link.hover,
.btn.btn-orange.btn-link:focus,
.btn.btn-orange.btn-link.focus,
.btn.btn-orange.btn-link:active,
.btn.btn-orange.btn-link.active {
  color: #ef9e1c;
}
.btn.btn-orange.btn-o:hover,
.btn.btn-orange.btn-o.hover,
.btn.btn-orange.btn-o:focus,
.btn.btn-orange.btn-o.focus,
.btn.btn-orange.btn-o:active,
.btn.btn-orange.btn-o.active {
  border-color: #ef9e1c;
}

/* |--------------[ Green Button ]--------------| */

.btn.btn-green {
  background-color: #88c724;
  color: #fff;
}
.btn.btn-green.btn-link,
.btn.btn-green.btn-o {
  color: #88c724;
  background-color: transparent;
}
.btn.btn-green.btn-o {
  border-color: #88c724;
}
.btn.btn-green:hover,
.btn.btn-green.hover,
.btn.btn-green:focus,
.btn.btn-green.focus,
.btn.btn-green:active,
.btn.btn-green.active {
  color: #fff;
  background-color: #78A931;
  border-color: #78A931;
}
.btn.btn-green.btn-link:hover,
.btn.btn-green.btn-link.hover,
.btn.btn-green.btn-link:focus,
.btn.btn-green.btn-link.focus,
.btn.btn-green.btn-link:active,
.btn.btn-green.btn-link.active {
  color: #78A931;
}
.btn.btn-green.btn-o:hover,
.btn.btn-green.btn-o.hover,
.btn.btn-green.btn-o:focus,
.btn.btn-green.btn-o.focus,
.btn.btn-green.btn-o:active,
.btn.btn-green.btn-o.active {
  border-color: #78A931;
}

/* |--------------[ pink Button ]--------------| */

.btn.btn-pink {
  background-color: #FC3951;
  color: #fff;
}
.btn.btn-pink.btn-link,
.btn.btn-pink.btn-o {
  color: #FC3951;
  background-color: transparent;
}
.btn.btn-pink.btn-o {
  border-color: #FC3951;
}
.btn.btn-pink:hover,
.btn.btn-pink.hover,
.btn.btn-pink:focus,
.btn.btn-pink.focus,
.btn.btn-pink:active,
.btn.btn-pink.active {
  color: #fff;
  background-color: #e62c43;
  border-color: #e62c43;
}
.btn.btn-pink.btn-link:hover,
.btn.btn-pink.btn-link.hover,
.btn.btn-pink.btn-link:focus,
.btn.btn-pink.btn-link.focus,
.btn.btn-pink.btn-link:active,
.btn.btn-pink.btn-link.active {
  color: #e62c43;
}
.btn.btn-pink.btn-o:hover,
.btn.btn-pink.btn-o.hover,
.btn.btn-pink.btn-o:focus,
.btn.btn-pink.btn-o.focus,
.btn.btn-pink.btn-o:active,
.btn.btn-pink.btn-o.active {
  border-color: #e62c43;
}

/* |--------------[ pink Button ]--------------| */

.btn.btn-purple {
  background-color: #9664fb;
  color: #fff;
}
.btn.btn-purple.btn-link,
.btn.btn-purple.btn-o {
  color: #9664fb;
  background-color: transparent;
}
.btn.btn-purple.btn-o {
  border-color: #9664fb;
}
.btn.btn-purple:hover,
.btn.btn-purple.hover,
.btn.btn-purple:focus,
.btn.btn-purple.focus,
.btn.btn-purple:active,
.btn.btn-purple.active {
  color: #fff;
  background-color: #7347cc;
  border-color: #7347cc;
}
.btn.btn-purple.btn-link:hover,
.btn.btn-purple.btn-link.hover,
.btn.btn-purple.btn-link:focus,
.btn.btn-purple.btn-link.focus,
.btn.btn-purple.btn-link:active,
.btn.btn-purple.btn-link.active {
  color: #7347cc;
}
.btn.btn-purple.btn-o:hover,
.btn.btn-purple.btn-o.hover,
.btn.btn-purple.btn-o:focus,
.btn.btn-purple.btn-o.focus,
.btn.btn-purple.btn-o:active,
.btn.btn-purple.btn-o.active {
  border-color: #7347cc;
}

/* |--------------[ Button Skins ]--------------| */

.btn.btn-rev {
  background-color: #fff;
  color: #00AEFD;
}
.btn.btn-rev.btn-link,
.btn.btn-rev.btn-o {
  color: #fff;
  background-color: transparent;
}
.btn.btn-rev.btn-o {
  border-color: #fff;
}
.btn.btn-rev:hover,
.btn.btn-rev.hover,
.btn.btn-rev:focus,
.btn.btn-rev.focus,
.btn.btn-rev:active,
.btn.btn-rev.active {
  color: #00AEFD;
  background-color: #fff;
  border-color: #fff;
}
.btn.btn-rev.btn-link:hover,
.btn.btn-rev.btn-link.hover,
.btn.btn-rev.btn-link:focus,
.btn.btn-rev.btn-link.focus,
.btn.btn-rev.btn-link:active,
.btn.btn-rev.btn-link.active {
  color: #fff;
}
.btn.btn-rev.btn-o:hover,
.btn.btn-rev.btn-o.hover,
.btn.btn-rev.btn-o:focus,
.btn.btn-rev.btn-o.focus,
.btn.btn-rev.btn-o:active,
.btn.btn-rev.btn-o.active {
  border-color: #fff;
}

/* |--------------[ Dark Button ]--------------| */

.btn.btn-dark {
  background-color: rgb(39, 48, 57);
  color: #fff;
}
.btn.btn-dark.btn-link,
.btn.btn-dark.btn-o {
  color: rgb(39, 48, 57);
  background-color: transparent;
}
.btn.btn-dark.btn-o {
  border-color: rgb(39, 48, 57);
}
.btn.btn-dark:hover,
.btn.btn-dark.hover,
.btn.btn-dark:focus,
.btn.btn-dark.focus,
.btn.btn-dark:active,
.btn.btn-dark.active {
  color: #fff;
  background-color: rgb(53, 66, 79);
  ;
  border-color: rgb(53, 66, 79);
  ;
}
.btn.btn-dark.btn-link:hover,
.btn.btn-dark.btn-link.hover,
.btn.btn-dark.btn-link:focus,
.btn.btn-dark.btn-link.focus,
.btn.btn-dark.btn-link:active,
.btn.btn-dark.btn-link.active {
  color: rgb(53, 66, 79);
  ;
}
.btn.btn-dark.btn-o:hover,
.btn.btn-dark.btn-o.hover,
.btn.btn-dark.btn-o:focus,
.btn.btn-dark.btn-o.focus,
.btn.btn-dark.btn-o:active,
.btn.btn-dark.btn-o.active {
  border-color: rgb(53, 66, 79);
  ;
}

/* |--------------[ Orange Button ]--------------| */

.btn.btn-orange {
  background-color: #FFA91E;
  color: #fff;
}
.btn.btn-orange.btn-link,
.btn.btn-orange.btn-o {
  color: #FFA91E;
  background-color: transparent;
}
.btn.btn-orange.btn-o {
  border-color: #FFA91E;
}
.btn.btn-orange:hover,
.btn.btn-orange.hover,
.btn.btn-orange:focus,
.btn.btn-orange.focus,
.btn.btn-orange:active,
.btn.btn-orange.active {
  color: #fff;
  background-color: #ef9e1c;
  border-color: #ef9e1c;
}
.btn.btn-orange.btn-link:hover,
.btn.btn-orange.btn-link.hover,
.btn.btn-orange.btn-link:focus,
.btn.btn-orange.btn-link.focus,
.btn.btn-orange.btn-link:active,
.btn.btn-orange.btn-link.active {
  color: #ef9e1c;
}
.btn.btn-orange.btn-o:hover,
.btn.btn-orange.btn-o.hover,
.btn.btn-orange.btn-o:focus,
.btn.btn-orange.btn-o.focus,
.btn.btn-orange.btn-o:active,
.btn.btn-orange.btn-o.active {
  border-color: #ef9e1c;
}


/* --------------------------------------------- */
/* ------------->>> PAGE LAYOUT  <<<------------ */
/* --------------------------------------------- */

.container {
  position: relative;
}
.row {
  margin-top: -30px;
  margin-bottom: 0;
}
.col-inner {
  position: relative;
  height: 100%;
  width: 100%;
}
.row > [class*="col-"] {
  padding-top: 30px;
  padding-bottom: 0;
}
.row.no-gutter {
  margin: 0;
}
.row.no-gutter > [class*="col-"] {
  padding: 0;
}
.row.row-rl-0 {
  margin-right: 0px;
  margin-left: 0px;
}
.row.row-rl-0 > [class*="col-"] {
  padding-right: 0px;
  padding-left: 0px;
}
.row.row-rl-5 {
  margin-right: -5px;
  margin-left: -5px;
}
.row.row-rl-5 > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.row.row-rl-10 {
  margin-right: -10px;
  margin-left: -10px;
}
.row.row-rl-10 > [class*="col-"] {
  padding-right: 10px;
  padding-left: 10px;
}
.row.row-rl-20 {
  margin-right: -20px;
  margin-left: -20px;
}
.row.row-rl-20 > [class*="col-"] {
  padding-right: 20px;
  padding-left: 20px;
}
.row.row-rl-30 {
  margin-right: -30px;
  margin-left: -30px;
}
.row.row-rl-30 > [class*="col-"] {
  padding-right: 30px;
  padding-left: 30px;
}
.row.row-tb-0 {
  margin-top: 0;
}
.row.row-tb-0 > [class*="col-"] {
  padding-top: 0;
}
.row.row-tb-5 {
  margin-top: -10px;
}
.row.row-tb-5 > [class*="col-"] {
  padding-top: 10px;
}
.row.row-tb-10 {
  margin-top: -20px;
}
.row.row-tb-10 > [class*="col-"] {
  padding-top: 20px;
  padding-bottom: 0;
}
.row.row-tb-20 {
  margin-top: -40px;
  margin-bottom: 0;
}
.row.row-tb-20 > [class*="col-"] {
  padding-top: 40px;
  padding-bottom: 0;
}
.row.row-tb-25 {
  margin-top: -50px;
}
.row.row-tb-25 > [class*="col-"] {
  padding-top: 50px;
}
.row.row-tb-30 {
  margin-top: -60px;
}
.row.row-tb-30 > [class*="col-"] {
  padding-top: 60px;
}
.row.row-tb-35 {
  margin-top: -70px;
}
.row.row-tb-35 > [class*="col-"] {
  padding-top: 70px;
}
.row.row-tb-40 {
  margin-top: -80px;
}
.row.row-tb-40 > [class*="col-"] {
  padding-top: 80px;
}
.col-center,
.col-xs-center {
  float: none;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .col-sm-center {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .col-md-center {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .col-lg-center {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}
.row-xs-cell {
  display: table;
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
}
.row-xs-cell > [class*="col-xs"],
.row-xs-cell > [class*="col-sm"],
.row-xs-cell > [class*="col-md"],
.row-xs-cell > [class*="col-lg"] {
  float: none;
  display: table-cell;
  vertical-align: middle;
  height: 1px;
}
.row-xs-cell > [class*="col-xs"]:first-child,
.row-xs-cell > [class*="col-sm"]:first-child,
.row-xs-cell > [class*="col-md"]:first-child,
.row-xs-cell > [class*="col-lg"]:first-child {
  padding-left: 0;
}
.row-xs-cell > [class*="col-xs"]:last-child,
.row-xs-cell > [class*="col-sm"]:last-child,
.row-xs-cell > [class*="col-md"]:last-child,
.row-xs-cell > [class*="col-lg"]:last-child {
  padding-right: 0;
}
.row-xs-cell > [class*="col-xs"] .col-absolute-cell,
.row-xs-cell > [class*="col-sm"] .col-absolute-cell,
.row-xs-cell > [class*="col-md"] .col-absolute-cell,
.row-xs-cell > [class*="col-lg"] .col-absolute-cell {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  padding: inherit;
  background-clip: content-box;
}
@media (min-width: 768px) {
  .row-sm-cell {
    display: table;
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
  }
  .row-sm-cell > [class*="col-xs"],
  .row-sm-cell > [class*="col-sm"],
  .row-sm-cell > [class*="col-md"],
  .row-sm-cell > [class*="col-lg"] {
    float: none;
    display: table-cell;
    vertical-align: middle;
    height: 1px;
  }
  .row-sm-cell > [class*="col-xs"]:first-child,
  .row-sm-cell > [class*="col-sm"]:first-child,
  .row-sm-cell > [class*="col-md"]:first-child,
  .row-sm-cell > [class*="col-lg"]:first-child {
    padding-left: 0;
  }
  .row-sm-cell > [class*="col-xs"]:last-child,
  .row-sm-cell > [class*="col-sm"]:last-child,
  .row-sm-cell > [class*="col-md"]:last-child,
  .row-sm-cell > [class*="col-lg"]:last-child {
    padding-right: 0;
  }
  .row-sm-cell > [class*="col-xs"] .col-absolute-cell,
  .row-sm-cell > [class*="col-sm"] .col-absolute-cell,
  .row-sm-cell > [class*="col-md"] .col-absolute-cell,
  .row-sm-cell > [class*="col-lg"] .col-absolute-cell {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    padding: inherit;
    background-clip: content-box;
  }
}
@media (min-width: 992px) {
  .row-md-cell {
    display: table;
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
  }
  .row-md-cell > [class*="col-xs"],
  .row-md-cell > [class*="col-sm"],
  .row-md-cell > [class*="col-md"],
  .row-md-cell > [class*="col-lg"] {
    float: none;
    display: table-cell;
    vertical-align: middle;
    height: 1px;
  }
  .row-md-cell > [class*="col-xs"]:first-child,
  .row-md-cell > [class*="col-sm"]:first-child,
  .row-md-cell > [class*="col-md"]:first-child,
  .row-md-cell > [class*="col-lg"]:first-child {
    padding-left: 0;
  }
  .row-md-cell > [class*="col-xs"]:last-child,
  .row-md-cell > [class*="col-sm"]:last-child,
  .row-md-cell > [class*="col-md"]:last-child,
  .row-md-cell > [class*="col-lg"]:last-child {
    padding-right: 0;
  }
  .row-md-cell > [class*="col-xs"] .col-absolute-cell,
  .row-md-cell > [class*="col-sm"] .col-absolute-cell,
  .row-md-cell > [class*="col-md"] .col-absolute-cell,
  .row-md-cell > [class*="col-lg"] .col-absolute-cell {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    padding: inherit;
    background-clip: content-box;
  }
}
@media (min-width: 1200px) {
  .row-lg-cell {
    display: table;
    width: 100%;
    height: 100%;
  }
  .row-lg-cell > [class*="col-xs"],
  .row-lg-cell > [class*="col-sm"],
  .row-lg-cell > [class*="col-md"],
  .row-lg-cell > [class*="col-lg"] {
    float: none;
    display: table-cell;
    vertical-align: middle;
    height: 1px;
  }
  .row-lg-cell > [class*="col-xs"]:first-child,
  .row-lg-cell > [class*="col-sm"]:first-child,
  .row-lg-cell > [class*="col-md"]:first-child,
  .row-lg-cell > [class*="col-lg"]:first-child {
    padding-left: 0;
  }
  .row-lg-cell > [class*="col-xs"]:last-child,
  .row-lg-cell > [class*="col-sm"]:last-child,
  .row-lg-cell > [class*="col-md"]:last-child,
  .row-lg-cell > [class*="col-lg"]:last-child {
    padding-right: 0;
  }
  .row-lg-cell > [class*="col-xs"] .col-absolute-cell,
  .row-lg-cell > [class*="col-sm"] .col-absolute-cell,
  .row-lg-cell > [class*="col-md"] .col-absolute-cell,
  .row-lg-cell > [class*="col-lg"] .col-absolute-cell {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    padding: inherit;
    background-clip: content-box;
  }
}

/*  Bootstrap Clearfix */

@media (min-width: 767px) {
  .row-masnory > .col-lg-1:nth-child(12n+1),
  .row-masnory > .col-lg-2:nth-child(6n+1),
  .row-masnory > .col-lg-3:nth-child(4n+1),
  .row-masnory > .col-lg-4:nth-child(3n+1),
  .row-masnory > .col-lg-6:nth-child(2n+1),
  .row-masnory > .col-md-1:nth-child(12n+1),
  .row-masnory > .col-md-2:nth-child(6n+1),
  .row-masnory > .col-md-3:nth-child(4n+1),
  .row-masnory > .col-md-4:nth-child(3n+1),
  .row-masnory > .col-md-6:nth-child(2n+1) {
    clear: none;
  }
  .row-masnory > .col-sm-1:nth-child(12n+1),
  .row-masnory > .col-sm-2:nth-child(6n+1),
  .row-masnory > .col-sm-3:nth-child(4n+1),
  .row-masnory > .col-sm-4:nth-child(3n+1),
  .row-masnory > .col-sm-6:nth-child(2n+1) {
    clear: left;
  }
}
@media (min-width: 992px) {
  .row-masnory > .col-lg-1:nth-child(12n+1),
  .row-masnory > .col-lg-2:nth-child(6n+1),
  .row-masnory > .col-lg-3:nth-child(4n+1),
  .row-masnory > .col-lg-4:nth-child(3n+1),
  .row-masnory > .col-lg-6:nth-child(2n+1),
  .row-masnory > .col-sm-1:nth-child(12n+1),
  .row-masnory > .col-sm-2:nth-child(6n+1),
  .row-masnory > .col-sm-3:nth-child(4n+1),
  .row-masnory > .col-sm-4:nth-child(3n+1),
  .row-masnory > .col-sm-6:nth-child(2n+1) {
    clear: none;
  }
  .row-masnory > .col-md-1:nth-child(12n+1),
  .row-masnory > .col-md-2:nth-child(6n+1),
  .row-masnory > .col-md-3:nth-child(4n+1),
  .row-masnory > .col-md-4:nth-child(3n+1),
  .row-masnory > .col-md-6:nth-child(2n+1) {
    clear: left;
  }
}
@media (min-width: 1200px) {
  .row-masnory > .col-md-1:nth-child(12n+1),
  .row-masnory > .col-md-2:nth-child(6n+1),
  .row-masnory > .col-md-3:nth-child(4n+1),
  .row-masnory > .col-md-4:nth-child(3n+1),
  .row-masnory > .col-md-6:nth-child(2n+1),
  .row-masnory > .col-sm-1:nth-child(12n+1),
  .row-masnory > .col-sm-2:nth-child(6n+1),
  .row-masnory > .col-sm-3:nth-child(4n+1),
  .row-masnory > .col-sm-4:nth-child(3n+1),
  .row-masnory > .col-sm-6:nth-child(2n+1) {
    clear: none;
  }
  .row-masnory > .col-lg-1:nth-child(12n+1),
  .row-masnory > .col-lg-2:nth-child(6n+1),
  .row-masnory > .col-lg-3:nth-child(4n+1),
  .row-masnory > .col-lg-4:nth-child(3n+1),
  .row-masnory > .col-lg-6:nth-child(2n+1) {
    clear: left;
  }
}
.page-wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: #fff;
}
.boxed-layout {
  background-color: #eee;
}
.boxed-layout .page-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}
.preloader {
  position: fixed;
  z-index: 9999;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
}
.is-mobile .preloader {
  display: none;
}
.no-js .preloader {
  display: none;
}
.section {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.embed-responsive-1by1 {
  padding-bottom: 100%;
}

/* List Styles */

.list-styled li {
  padding: 5px 0 5px 28px;
  line-height: 1.8;
  position: relative;
}
.list-styled li:before {
  content: '';
  display: block;
  position: absolute;
  padding: 4px;
  border-radius: 50%;
  border: 3px solid #00aefd;
  height: 0;
  width: 0;
  left: 0;
  top: 10px;
}
.fa {
  font-size: 1.1em;
}
.ls-1 {
  letter-spacing: 1px;
}

/* Fix Bootstrap Media */

.media-object {
  max-width: none;
}

/* Social Icons */

.social-icons__item .fa {
  font-size: 15px;
  margin: 0;
  height: 32px;
  width: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 3px;
  color: #F5F5F5;
  background-color: rgba(49, 69, 85, 0.25);
  -webkit-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
.social-icons .social-icons__item i.fa-facebook:hover {
  background-color: #3b5998;
}
.social-icons .social-icons__item i.fa-twitter:hover {
  background-color: #1da1f2;
}
.social-icons .social-icons__item i.fa-google-plus:hover {
  background-color: #dd4b39;
}
.social-icons .social-icons__item i.fa-linkedin:hover {
  background-color: #0077b5;
}
.social-icons .social-icons__item i.fa-pinterest:hover {
  background-color: #cc2222;
}
.social-icons--circle .fa {
  height: 36px;
  width: 36px;
  line-height: 33px;
  border-radius: 999px;
  background-color: transparent;
  border: 2px solid #F5F5F5;
}

/* Pagination */

.pagination {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
}
.pagination > li {
  display: inline-block;
  margin: 0 2px;
}
.pagination > li > a,
.pagination > li > span {
  display: block;
  border: 2px solid #e9e9e9;
  font-size: 15px;
  border-radius: 4px;
  padding: 8px 15px;
  float: none;
  color: #474c66;
}
.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus,
.pagination > .active > a,
.pagination > .active > span {
  background-color: #00aefd;
  border-color: #00aefd;
  color: #fff;
}

/* Breadcrumb */

.breadcrumb {
  background-color: transparent;
  font-size: 15px;
}
.breadcrumb > li + li:before {
  color: #f1f1f1;
}
.breadcrumb > li a {
  color: #f1f1f1;
}
.breadcrumb > li a:hover,
.breadcrumb > .active {
  color: #fff;
}

/* Instagram Widget */

.instagram-widget img {
  width: 100%;
}
.dots,
.dots:before,
.dots:after {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 999px;
}
.dots {
  position: relative;
  margin-right: 15px;
  margin-left: 15px;
}
.dots:before,
.dots:after {
  content: "";
  position: absolute;
  background-color: inherit;
}
.dots:before {
  right: 15px;
}
.dots:after {
  left: 15px;
}
.dots.dots--small,
.dots.dots--small:before,
.dots.dots--small:after {
  width: 5px;
  height: 5px;
}
.dots.dots--small {
  margin-right: 12px;
  margin-left: 12px;
}
.dots.dots--small:before {
  right: 12px;
}
.dots.dots--small:after {
  left: 12px;
}
.zoom > img,
.zoom figure > img {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.zoom,
.zoom figure {
  display: block;
  overflow: hidden;
}
.zoom:hover > img,
.zoom:hover figure > img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

/*Bootstrap Accordion Start*/

.panel-group .panel {
  border: 0;
  margin-bottom: 0;
}
.panel-group .panel + .panel {
  margin-top: 10px;
}
.panel-group .panel-body {
  padding: 20px 15px;
}
.panel-group .panel-heading {
  padding: 0;
}
.panel-group .panel-title a,
.panel-group .panel-title a:focus {
  font-size: 14px;
  position: relative;
  display: block;
  padding: 10px 0 10px 22px;
  background-color: #ffa91e;
  color: #313131;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #fff;
  border-radius: 4px;
  border: 0;
  text-shadow: 0px 0px 1px #eee;
}
.panel-group .panel-title a.collapsed,
.panel-group .panel-title a.collapsed:focus {
  background-color: #fff;
  color: #313131;
}
.panel-group .panel-title a:before {
  content: '\f055';
  font-family: fontawesome;
  margin-right: 10px;
  font-size: 16px;
}
.panel-group .panel-title a[aria-expanded="true"]:before {
  content: '\f056';
}
.panel-group .collapse {
  padding: 0;
}
.panel-group .panel-heading+.panel-collapse>.list-group,
.panel-group .panel-heading+.panel-collapse>.panel-body {
  border: 0;
}

/* Owl Carousel */

.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}
.owl-controls-1 .owl-nav {
  margin-top: 0;
}
.owl-controls-1 .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  font-size: 24px;
  margin: 0;
  padding: 20px 10px;
  text-align: center;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.3);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 400ms linear 0ms;
}
.owl-controls-1 .owl-nav [class*=owl-]:hover {
  background: rgba(0, 0, 0, 0.5);
}
.owl-controls-1 .owl-nav .owl-prev {
  left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.owl-controls-1 .owl-nav .owl-next {
  right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

/* --------------------------------------------- */
/* ---------->>> Animated Headline  <<<--------- */
/* --------------------------------------------- */

.cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
}
.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
}
.cd-words-wrapper b.is-visible {
  position: relative;
}
.no-js .cd-words-wrapper b {
  opacity: 0;
}
.no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}
.cd-headline {
  line-height: 1;
  margin: 35px 0;
}
.cd-headline.clip span {
  display: inline-block;
  padding: .2em 0;
}
.cd-headline.clip .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}
.cd-headline.clip .cd-words-wrapper::after {
  /* line */
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background-color: #aebcb9;
}
.cd-headline.clip b {
  opacity: 0;
}
.cd-headline.clip b.is-visible {
  opacity: 1;
}

/* --------------------------------------------- */
/* -------------->>> Preloader  <<<------------- */
/* --------------------------------------------- */

.preloader {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.loader {
  text-align: center;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  width: 100px;
  height: 100px;
}
.loader > span,
.loader > span:before,
.loader > span:after {
  content: "";
  display: block;
  border-radius: 50%;
  border: 2px solid #00AEFD;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.preloader.orange .loader > span,
.preloader.orange .loader > span:before,
.preloader.orange .loader > span:after {
  border-color: #FFA91E;
}
.preloader.green .loader > span,
.preloader.green .loader > span:before,
.preloader.green .loader > span:after {
  border-color: #88c724;
}
.preloader.pink .loader > span,
.preloader.pink .loader > span:before,
.preloader.pink .loader > span:after {
  border-color: #FC3951;
}
.preloader.purple .loader > span,
.preloader.purple .loader > span:before,
.preloader.purple .loader > span:after {
  border-color: #9664fb;
}
.loader > span {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-left-color: transparent!important;
  -webkit-animation: effect-2 2s infinite linear;
  -moz-animation: effect-2 2s infinite linear;
  -ms-animation: effect-2 2s infinite linear;
  -o-animation: effect-2 2s infinite linear;
  animation: effect-2 2s infinite linear;
}
.loader > span:before {
  width: 75%;
  height: 75%;
  border-right-color: transparent!important;
}
.loader > span:after {
  width: 50%;
  height: 50%;
  border-bottom-color: transparent!important;
}
@-webkit-keyframes effect-2 {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes effect-2 {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* --------------------------------------------- */
/* ------------->>> Back to Top  <<<------------ */
/* --------------------------------------------- */
/* -------------[ Back Top Styles ]------------- */

.back-top {
  z-index: 9999;
  position: fixed;
  right: 30px;
  bottom: -10px;
  opacity: 0;
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 400ms linear 0ms;
  -ms-transition: all 400ms linear 0ms;
  -o-transition: all 400ms linear 0ms;
  transition: all 400ms linear 0ms;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 20px;
}
.back-top:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.back-top.show {
  opacity: 1;
  bottom: 30px;
}

/* --------------------------------------------- */
/* ------------>>> Main Header Area  <<<-------- */
/* --------------------------------------------- */

.main-header {
  position: relative;
  margin-top: 80px;
}
.navbar {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  top: 0;
  border-radius: 0;
  border: 0;
  margin: 0;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.3s linear;
  background-image: none;
}
.navbar .navbar-brand {
  color: #2a3e53;
  font-size: 22px;
  height: 80px;
  padding: 20px !important;
}
.navbar .navbar-brand > img {
  height: 40px;
  display: inline-block;
}
.navbar .phone {
  display: inline-block;
  height: 80px;
  line-height: 80px;
  margin-left: 10px;
  font-size: 14px;
  color: #999;
  vertical-align: top;
  font-weight: bold;
}
.navbar .phone .fa {
  font-size: 16px;
  color: #00aefd;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #ddd;
}
.navbar .navbar-nav {
  padding: 20px 0;
}
.navbar-light .navbar-nav .nav-link {
  color: black;
}


.navbar .navbar-nav > a,
.navbar .navbar-nav > a:focus,
.navbar .navbar-nav > .nav-item > a,
.navbar .navbar-nav > .nav-item > a:focus {
  height: 40px;
  padding: 10px 12px;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: #919191;
}
.navbar .navbar-nav li.signup a,
.navbar .navbar-nav li.signup a.active {
  border-color: transparent;
  padding: 7px 20px 8px;
  margin: 0 12px;
  letter-spacing: 0.3px;
  background-color: #00aefd;
  color: #fff;
  font-size: 14px;
}
.navbar .navbar-nav li.signup a:hover,
.navbar .navbar-nav li.signup a:focus {
  background-color: #019be0;
  color: #fff;
}
.navbar .navbar-nav li.active a,
.navbar .navbar-nav > li > a:hover,
.navbar .navbar-nav > .open > a,
.navbar .navbar-nav > .open > a:focus,
.navbar .navbar-nav > .open > a:hover,
.navbar .navbar-nav > .active > a,
.navbar .navbar-nav > .active > a:hover,
.navbar .navbar-nav > .active > a:focus {
  background-color: transparent;
  background-image: none;
  color: #00aefd;
}
.navbar-toggle {
  margin-top: 11px;
  border: 2px solid #ddd;
  padding: 8px;
  margin: 15px 20px 16px 0;
}
.navbar-toggle .icon-bar {
  width: 30px;
  -webkit-transition: all 400ms linear 0ms;
  -ms-transition: all 400ms linear 0ms;
  -o-transition: all 400ms linear 0ms;
  transition: all 400ms linear 0ms;
}
.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 6px;
}
.navbar .navbar-toggle:hover,
.navbar .navbar-toggle:focus {
  background-color: transparent;
  border-color: #00aefd;
}
.navbar .navbar-toggle:hover .icon-bar,
.navbar .navbar-toggle:focus .icon-bar {
  background-color: #00aefd;
}

@media (min-width: 767px) {
  .main-header.has-transparent {
    margin-top: 0;
  }
  .main-header.transparent .navbar {
    background-color: transparent;
    padding: 10px 0;
    box-shadow: none;
  }
  .main-header.transparent .navbar .phone {
    color: #ececec;
  }
  .main-header.transparent .navbar .navbar-nav > a,
  .main-header.transparent .navbar .navbar-nav > a:focus,
  .main-header.transparent .navbar .navbar-nav > .nav-item > a,
  .main-header.transparent .navbar .navbar-nav > .nav-item > a:focus {
    color: #ececec;
  }
}
@media (max-width: 768px) {
  .main-header {
    margin-top: 68px;
  }
  .navbar .navbar-nav {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  .navbar-nav .show .dropdown-menu > li > a,
  .navbar-nav .show .dropdown-menu .dropdown-header,
  .navbar .navbar-nav .nav-item a {
    font-size: 13px;
    padding: 12px 15px;
  }
  .navbar .navbar-nav .nav-item+.nav-item {
    border-top: 1px solid #e7e7e7;
  }
  .navbar .navbar-nav .nav-item {
    padding: 2px 0;
  }
  .navbar .navbar-nav li.signup {
    padding: 5px 0;
  }
  .navbar .navbar-nav li.active a,
  .navbar .navbar-nav li a:hover {
    background-color: transparent;
    color: #00aefd;
  }
  .navbar .navbar-brand {
    height: 60px;
    padding: 15px 20px !important;
    font-size: 20px;
  }
  .dropdown-menu {
    padding: 0 15px;
  }
}

.navbar-purple .navbar-nav li.signup a {
  background-color: #9664fb;
}
.navbar-purple .navbar-nav li.active a,
.navbar-purple .navbar-nav > li > a:hover,
.navbar-purple .navbar-nav > .open > a,
.navbar-purple .navbar-nav > .open > a:focus,
.navbar-purple .navbar-nav > .open > a:hover,
.navbar-purple .navbar-nav > .active > a,
.navbar-purple .navbar-nav > .active > a:hover,
.navbar-purple .navbar-nav > .active > a:focus {
  color: #9664fb;
}
@media screen and (max-width: 768px) {
  .navbar-purple .navbar-nav li.active a,
  .navbar-purple .navbar-nav li a:hover {
    background-color: transparent;
    color: #9664fb;
  }
}
.navbar-purple .navbar-toggle:hover,
.navbar-purple .navbar-toggle:focus {
  background-color: transparent;
  border-color: #9664fb;
}
.navbar-purple .navbar-toggle:hover .icon-bar,
.navbar-purple .navbar-toggle:focus .icon-bar {
  background-color: #9664fb;
}
.navbar-purple .navbar-nav li.signup a:hover,
.navbar-purple .navbar-nav li.signup a:focus {
  background-color: #7347cc;
}

.navbar-pink .navbar-nav li.signup a {
  background-color: #FC3951;
}
.navbar-pink .navbar-nav li.active a,
.navbar-pink .navbar-nav > li > a:hover,
.navbar-pink .navbar-nav > .open > a,
.navbar-pink .navbar-nav > .open > a:focus,
.navbar-pink .navbar-nav > .open > a:hover,
.navbar-pink .navbar-nav > .active > a,
.navbar-pink .navbar-nav > .active > a:hover,
.navbar-pink .navbar-nav > .active > a:focus {
  color: #FC3951;
}
.navbar-pink .navbar-toggle:hover,
.navbar-pink .navbar-toggle:focus {
  background-color: transparent;
  border-color: #FC3951;
}
.navbar-pink .navbar-toggle:hover .icon-bar,
.navbar-pink .navbar-toggle:focus .icon-bar {
  background-color: #FC3951;
}
@media screen and (max-width: 768px) {
  .navbar-pink .navbar-nav li.active a,
  .navbar-pink .navbar-nav li a:hover {
    background-color: transparent;
    color: #FC3951;
  }
}
.navbar-pink .navbar-nav li.signup a:hover,
.navbar-pink .navbar-nav li.signup a:focus {
  background-color: #e62c43;
}

.navbar-orange .navbar-nav li.signup a {
  background-color: #FFA91E;
}
.navbar-orange .navbar-nav li.active a,
.navbar-orange .navbar-nav > li > a:hover,
.navbar-orange .navbar-nav > .open > a,
.navbar-orange .navbar-nav > .open > a:focus,
.navbar-orange .navbar-nav > .open > a:hover,
.navbar-orange .navbar-nav > .active > a,
.navbar-orange .navbar-nav > .active > a:hover,
.navbar-orange .navbar-nav > .active > a:focus {
  color: #FFA91E;
}
.navbar-orange .navbar-toggle:hover,
.navbar-orange .navbar-toggle:focus {
  background-color: transparent;
  border-color: #FFA91E;
}
.navbar-orange .navbar-toggle:hover .icon-bar,
.navbar-orange .navbar-toggle:focus .icon-bar {
  background-color: #FFA91E;
}
@media screen and (max-width: 768px) {
  .navbar-orange .navbar-nav li.active a,
  .navbar-orange .navbar-nav li a:hover {
    background-color: transparent;
    color: #FFA91E;
  }
}
.navbar-orange .navbar-nav li.signup a:hover,
.navbar-orange .navbar-nav li.signup a:focus {
  background-color: #ef9e1c;
}

.navbar-green .navbar-nav li.signup a {
  background-color: #88c724;
}
.navbar-green .navbar-nav li.active a,
.navbar-green .navbar-nav > li > a:hover,
.navbar-green .navbar-nav > .open > a,
.navbar-green .navbar-nav > .open > a:focus,
.navbar-green .navbar-nav > .open > a:hover,
.navbar-green .navbar-nav > .active > a,
.navbar-green .navbar-nav > .active > a:hover,
.navbar-green .navbar-nav > .active > a:focus {
  color: #88c724;
}
.navbar-green .navbar-toggle:hover,
.navbar-green .navbar-toggle:focus {
  background-color: transparent;
  border-color: #88c724;
}
.navbar-green .navbar-toggle:hover .icon-bar,
.navbar-green .navbar-toggle:focus .icon-bar {
  background-color: #88c724;
}
@media screen and (max-width: 768px) {
  .navbar-green .navbar-nav li.active a,
  .navbar-green .navbar-nav li a:hover {
    background-color: transparent;
    color: #88c724;
  }
}
.navbar-green .navbar-nav li.signup a:hover,
.navbar-green .navbar-nav li.signup a:focus {
  background-color: #78A931;
}

/* --------------------------------------------- */
/* -------------->>> Hero Area  <<<------------- */
/* --------------------------------------------- */

.main-header + .main-content .hero-area .h-full-screen {
  min-height: calc(100vh - 80px) !important;
}
.main-header.has-transparent + .main-content .hero-area .h-full-screen {
  min-height: 100vh !important;
}
@media (max-width: 768px) {
  .main-header + .main-content .hero-area .h-full-screen {
    min-height: calc(100vh - 68px) !important;
  }
}
.hero-gradient {
  background-color: #4e0473;
  background-image: -webkit-linear-gradient(bottom, #4e0473, #0b0010);
  background-image: -moz-linear-gradient(bottom, #4e0473, #0b0010);
  background-image: -o-linear-gradient(bottom, #4e0473, #0b0010);
  background-image: -ms-linear-gradient(bottom, #4e0473, #0b0010);
  background-image: linear-gradient(to top, #4e0473, #0b0010);
}

/* --------------------------------------------- */
/* -------------->>> Help Area  <<<------------- */
/* --------------------------------------------- */

.cta-help-area .contact-info {
  font-size: 18px;
}
.cta-help-area a {
  padding: 0 15px;
  line-height: 1;
}

/* --------------------------------------------- */
/* ------------>>> Two Cols Area  <<<----------- */
/* --------------------------------------------- */

.two-cols-area .service-box {
  position: relative;
  padding: 15px 20px;
  background-color: rgba(56, 79, 96, 0.06);
}
.two-cols-area .service-box h5 {
  padding: 10px 0 10px 40px;
}
.two-cols-area .service-box i {
  position: absolute;
  top: 24px;
  font-size: 30px;
  color: #00aefd;
}
.two-cols-area .skills-list h4 {
  font-size: 15px;
  margin-bottom: 10px;
}
.two-cols-area .skill-progress {
  display: block;
  width: 100%;
  height: 5px;
  position: relative;
  margin-bottom: 20px;
}
.skills-list li:last-child .skill-progress {
  margin-bottom: 0;
}
.skills-list .skill-percentage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  background-color: #2ed87b;
  -webkit-transition: width .6s ease;
  -moz-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
}

/* --------------------------------------------- */
/* --------------->>> Video Area  <<<----------- */
/* --------------------------------------------- */

.video-area .play-icon {
  display: block;
  border-radius: 999px;
  height: 80px;
  width: 80px;
}

/* --------------------------------------------- */
/* ----------->>> Services Area  <<<--------- */
/* --------------------------------------------- */

.services-one .service-single {
  margin-top: 35px;
  position: relative;
  background-color: #fff;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(222, 222, 222, 1);
}
.services-one .service-icon {
  background-color: #fff;
  width: 70px;
  height: 70px;
  line-height: 68px;
  border: 2px solid;
  font-size: 30px;
}
.services-three .column {
  padding: 20px 20px 20px 80px;
  position: relative;
}
.services-three .column .icon {
  position: absolute;
  top: 15px;
  left: 20px;
}
.services-four .icon {
  display: inline-block;
  font-size: 30px;
  width: 62px;
  height: 62px;
  line-height: 62px;
  text-align: center;
  border-radius: 999px;
  color: #fff;
  margin-top: 8px;
}

/* --------------------------------------------- */
/* ----------->>> Features Area  <<<--------- */
/* --------------------------------------------- */

.features-one {
  box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.13);
}
.features-area .features-wrapper {
  -webkit-box-shadow: 0px 4px 16px 3px rgba(213, 221, 234, .5);
  -moz-box-shadow: 0px 4px 16px 3px rgba(213, 221, 234, .5);
  box-shadow: 0px 4px 16px 3px rgba(213, 221, 234, .5);
}
.features-area .feature-single {
  border: 1px solid #dee4ee;
}

/* --------------------------------------------- */
/* ------------>>> Fact Counter Area  <<<---------- */
/* --------------------------------------------- */

.fun-facts-area .counter-icon {
  display: inline-block;
}

/* --------------------------------------------- */
/* ------------>>> Pricing Table Area  <<<---------- */
/* --------------------------------------------- */

.pricing-one {
  position: relative;
  z-index: 1;
}
.pricing-one .pricing-plan {
  background-color: #fff;
  color: #0E1D40;
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  margin-bottom: 30px;
  border: 1px solid #eee;
  transition: box-shadow .5s;
}
.pricing-one .plan-recommended,
.pricing-one .pricing-plan:hover {
  -webkit-box-shadow: 0px 4px 26px 3px rgba(213, 221, 234, 1);
  -moz-box-shadow: 0px 4px 26px 3px rgba(213, 221, 234, 1);
  box-shadow: 0px 4px 26px 3px rgba(213, 221, 234, 1);
}
.pricing-one .plan-recommended {
  position: relative;
  overflow: hidden;
}
.pricing-one .plan-recommended > i {
  position: absolute;
  font-size: 30px;
  top: -4px;
  color: #f50;
}
.pricing-one .pricing-plan .plan-header {
  text-align: center;
}
.pricing-one .pricing-plan .plan-main ul {
  max-width: 250px;
  margin: 0 auto;
}
.pricing-one .pricing-plan .plan-main ul li {
  padding: 10px;
  font-weight: 600;
  color: #999;
}
.pricing-one .pricing-plan .plan-main ul li+li {
  border-top: 1px solid #eee;
}
.pricing-one .pricing-plan .plan-main ul li .fa {
  padding-right: 8px;
}
.pricing-one .pricing-plan .plan-main ul li .fa-check {
  color: #00adef;
}
.pricing-one .pricing-plan .plan-main ul li .fa-close {
  color: red;
}
.pricing-one .pricing-plan .plan-footer {
  text-align: center;
}
.pricing-one .pricing-plan .plan-footer .btn {
  margin-top: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.pricing-three .pricing-plan {
  border: 1px solid rgb(238, 238, 238);
  border-radius: 5px;
  overflow: hidden;
}
.pricing-three .price {
  color: #fff;
  position: relative;
  padding: 15px 0 18px;
  line-height: 1;
}
.pricing-three .price .currency-unit {
  position: relative;
  top: -17px;
  font-size: 18px;
  font-weight: 700;
}
.pricing-three .price .money {
  font-size: 40px;
}
.pricing-three .price .time {
  vertical-align: baseline;
  font-weight: 700;
}
.pricing-three .price-table-list li {
  padding: 8px 0px;
  border-radius: 2px;
}
.pricing-three .price-table-list li:nth-child(odd) {
  background-color: #f5f5f5;
}

/* --------------------------------------------- */
/* ----------->>> Testimonial Area  <<<--------- */
/* --------------------------------------------- */

.testimonials-area .testimonial-avatar {
  width: 100px;
  position: relative;
  border-radius: 999px;
}
.testimonials-one .testimonial-avatar:after {
  content: "\f10e";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 34px;
  width: 34px;
  line-height: 32px;
  border-radius: 999px;
  background-color: #00aefd;
  color: #fff;
  position: absolute;
  bottom: -17px;
  left: 36px;
  border: 2px solid rgba(255, 255, 255, 0.75);
}

/* --------------------------------------------- */
/* --------->>> Call to action Area  <<<-------- */
/* --------------------------------------------- */

.call-to-action-area {
  position: relative;
  z-index: 1;
  color: #fff;
}
.call-to-action-area:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0E1D40;
  opacity: .75;
  z-index: -1;
}

/* --------------------------------------------- */
/* ------------>>> Our Team Area  <<<----------- */
/* --------------------------------------------- */

.our-team-three .single-member {
  overflow: hidden;
}
.our-team-three .single-member-overlay {
  transition: all 0.5s ease-in-out;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
.our-team-three .single-member:hover .single-member-overlay {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}


/* --------------------------------------------- */
/* ----------->>> Portfolio Area  <<<----------- */
/* --------------------------------------------- */

.portfolio-area .portfolio-filter {
  padding: 0 0 40px;
  text-align: center;
}
.portfolio-area .portfolio-filter > div {
  display: inline-block;
  margin: 0 -3px;
}
.portfolio-area .portfolio-filter .filter {
  display: block;
  position: relative;
  margin: 0;
  padding: 12px 15px;
  border-width: 1px 0;
  border-style: solid;
  border-color: #e7e7e7;
  text-align: center;
  color: #555;
  background-color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 400ms ease-in-out;
}
.portfolio-area .portfolio-filter .filter:after {
  content: '';
  display: block;
  position: absolute;
  width: 1%;
  height: 0;
  border: 1px solid transparent;
  bottom: -1px;
  left: 50%;
  opacity: 0;
  transition: all 400ms ease-in-out;
}
.portfolio-area .portfolio-filter .filter.active:after,
.portfolio-area .portfolio-filter .filter:hover:after {
  opacity: 1;
  width: 80%;
  left: 10%;
  border-color: #00aefd;
}
.portfolio-area .mix {
  display: none;
}
.portfolio-area .portfolio-hover {
  position: relative;
}
.portfolio-area .portfolio-overlay {
  z-index: 1;
}
.portfolio-area .portfolio-overlay {
  text-align: center;
  color: #fff;
  opacity: 0;
  transform: scale(1);
  transition: all 0.4s ease-out;
}
.portfolio-hover:hover .portfolio-overlay {
  opacity: 1;
  transform: scale(1);
}
.portfolio-area .portfolio-overlay .portfolio-title {
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: bold;
  transform: translateY(-20px);
  transition: all 0.3s ease-out;
}
.portfolio-area .portfolio-overlay .portfolio-hoverview {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.75);
  width: 34px;
  height: 34px;
  line-height: 35px;
  border-radius: 34px;
  font-size: 14px;
  transform: translateY(20px);
  transition: all 0.3s ease-out 0.1s;
}
.portfolio-area .portfolio-hover:hover .portfolio-title,
.portfolio-area .portfolio-hover:hover .portfolio-hoverview {
  transform: translateY(0);
}
.portfolio-zoom {
  position: relative;
}
.portfolio-one .portfolio-zoom:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.2;
  z-index: 2;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.portfolio-one .portfolio-zoom:hover:before {
  opacity: 0.1;
}
.portfolio-zoom figure {
  overflow: hidden;
}
.portfolio-zoom figure > img {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.portfolio-zoom:hover figure > img {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

/* --------------------------------------------- */
/* ------------>>> FAQ Area  <<<---------- */
/* --------------------------------------------- */

.faqs-area .question a {
  display: block;
  padding: 10px 0 10px 30px;
  position: relative;
}
.faqs-area .question a:before {
  content: "\f059";
  font-size: 1.5em;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 0;
  font-size: 1.3em;
  top: 13px;
  color: #d4d8da;
}
.faqs-area .question a:hover:before {
  color: #FFA91E !important;
}

/* --------------------------------------------- */
/* ------------>>> Subscribe Area  <<<---------- */
/* --------------------------------------------- */

.subscribe-area .subscribe-form {
  position: relative;
  display: block;
  width: 100%;
  background-color: #fff;
  height: 46px;
  padding: 0 160px 0 10px;
  border-radius: 999px;
}
.subscribe-area .subscribe-form .form-control {
  border: 0;
}
.subscribe-area .subscribe-form .btn {
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
}

/* --------------------------------------------- */
/* ----------->>> Our Skills Area  <<<---------- */
/* --------------------------------------------- */

.skills-list li:not(:last-child) {
  margin-bottom: 30px;
}
.skills-list .skill-progress {
  display: block;
  width: 100%;
  height: 6px;
  position: relative;
  background-color: #dfdfdf;
}
.skills-list li:last-child .skill-progress {
  margin-bottom: 0;
}
.skills-list .skill-percentage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  background-color: #00aefd;
  -webkit-transition: width .6s ease;
  -moz-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
}
.skills-list .skill-percentage span {
  position: absolute;
  opacity: 0;
  right: -18px;
  bottom: 12px;
  width: 42px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  line-height: 23px;
  text-align: center;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, .5);
  color: #fff;
  -webkit-transition: opacity 2s ease;
  -moz-transition: opacity 2s ease;
  -o-transition: opacity 2s ease;
  transition: opacity 2s ease;
}
.skills-list .skill-percentage span:after {
  position: absolute;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-width: 6px 4px 0 4px;
  border-style: solid;
  border-color: rgba(0, 0, 0, .5) transparent transparent transparent;
  bottom: -6px;
  left: 50%;
  margin-left: -4px;
}

/* --------------------------------------------- */
/* ----------->>> Contact Us Area  <<<---------- */
/* --------------------------------------------- */

.form-control.input-error {
  border-color: #c00 !important;
}
.contact-us-area .contact-box {
  background-color: #f0f4f7;
  overflow: hidden;
  box-shadow: 0 2px 0px 0px #e8ecec;
  border-radius: 5px;
}
.contact-us-area .contact-icon i {
  display: inline-block;
  font-size: 35px;
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  color: #00AEFD;
  border-radius: 100%;
  box-shadow: 0 0px 0px 2px #00AEFD inset;
  margin-bottom: 20px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}
.contact-box:hover .contact-icon i {
  box-shadow: 0 0px 0px 46px #00AEFD inset;
  color: #fff;
}

.contact-us-area .contact-icon.green i {
  color: #88c724;
  box-shadow: 0 0px 0px 2px #88c724 inset;
}
.contact-box:hover .contact-icon.green i {
  box-shadow: 0 0px 0px 46px #88c724 inset;
}

.contact-us-area .contact-icon.pink i {
  color: #FC3951;
  box-shadow: 0 0px 0px 2px #FC3951 inset;
}
.contact-box:hover .contact-icon.pink i {
  box-shadow: 0 0px 0px 46px #FC3951 inset;
}

.contact-us-area .contact-icon.purple i {
  color: #9664fb;
  box-shadow: 0 0px 0px 2px #9664fb inset;
}
.contact-box:hover .contact-icon.purple i {
  box-shadow: 0 0px 0px 46px #9664fb inset;
}

.contact-us-area .contact-icon.orange i {
  color: #FFA91E;
  box-shadow: 0 0px 0px 2px #FFA91E inset;
}
.contact-box:hover .contact-icon.orange i {
  box-shadow: 0 0px 0px 46px #FFA91E inset;
}

.contact-us-three .contactinfo-list li+li {
  padding-top: 15px;
}
.contact-us-three .contact-wrapper {
  margin-top: -100px;
  -webkit-box-shadow: 0px -4px 16px 3px rgba(213, 221, 234, .4);
  -moz-box-shadow: 0px -4px 16px 3px rgba(213, 221, 234, .4);
  box-shadow: 0px -4px 16px 3px rgba(213, 221, 234, .4);
}

/* --------------------------------------------- */
/* -------------->>> BLOG AREA  <<<------------- */
/* --------------------------------------------- */

.blog-area .owl-theme .owl-nav {
  margin-top: 0;
}
.blog-area .owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  font-size: 20px;
  margin: 0;
  padding: 4px 8px;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 400ms linear 0ms;
  -ms-transition: all 400ms linear 0ms;
  -o-transition: all 400ms linear 0ms;
  transition: all 400ms linear 0ms;
}
.blog-area .owl-theme .owl-nav [class*=owl-]:hover {
  background: rgba(0, 0, 0, 0.7);
}
.blog-area .owl-carousel .owl-nav .owl-prev {
  left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.blog-area .owl-carousel .owl-nav .owl-next {
  right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.blog-area .entry .author img {
  width: 40px;
}
.blog-grid .entry {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 0;
  position: relative;
  border-radius: 3px;
  max-width: 600px;
  margin: 0 auto 2px;
  overflow: hidden;
}
.blog-grid .entry .entry-meta {
  background-color: #f8f8f8;
  color: #777;
  padding: 5px 15px;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
}
.blog-classic .entry-media {
  position: relative;
}
.blog-classic .entry-category {
  height: 34px;
  padding: 7px 15px;
  font-size: 13px;
  line-height: 20px;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  font-weight: bold;
  overflow: hidden;
  color: #fff;
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 30px;
  transform: translateY(50%);
}
.blog-classic .entry-tags a {
  font-size: 12px;
  display: inline-block;
  padding: 7px 20px;
  text-transform: uppercase;
  color: #00AEFD;
  border-radius: 999px;
  background: rgb(232, 248, 255);
  -webkit-transition: background .15s ease-out, color .15s ease-out;
  transition: background .15s ease-out, color .15s ease-out;
}
.blog-classic .entry-tags a:hover {
  color: #fff;
  background: #00AEFD;
}
.blog-classic .entry-footer .read-more {
  border-radius: 999px;
  color: #00AEFD;
  background-color: #ebf4f7;
  border: 0;
  padding: 10px 25px;
}
.postshare a {
  display: inline-block;
  text-align: center;
  border-radius: 30px;
  font-size: 15px;
  background: #315270;
  color: #fff;
  cursor: pointer;
  height: 38px;
  line-height: 38px;
  padding: 0 20px;
}
.postshare a:hover {
  opacity: .9
}
.postshare a i {
  padding-right: 8px;
  font-size: 1em;
}
.postshare a.share-facebook {
  background-color: #2b4170;
  background: -moz-linear-gradient(top, #3b5998, #2b4170);
  background: -ms-linear-gradient(top, #3b5998, #2b4170);
  background: -webkit-linear-gradient(top, #3b5998, #2b4170);
  background: -o-linear-gradient(top, #3b5998, #2b4170)
}
.postshare a.share-twitter {
  background-color: #0081ce;
  background: -moz-linear-gradient(top, #00aced, #0081ce);
  background: -ms-linear-gradient(top, #00aced, #0081ce);
  background: -webkit-linear-gradient(top, #00aced, #0081ce);
  background: -o-linear-gradient(top, #00aced, #0081ce)
}
.postshare a.share-gplus {
  background-color: #c33219;
  background: -moz-linear-gradient(top, #e64522, #c33219);
  background: -ms-linear-gradient(top, #e64522, #c33219);
  background: -webkit-linear-gradient(top, #e64522, #c33219);
  background: -o-linear-gradient(top, #e64522, #c33219)
}
.postshare a.share-linkedin {
  background-color: #0073b2;
  background: -moz-linear-gradient(top, #009cda, #0073b2);
  background: -ms-linear-gradient(top, #009cda, #0073b2);
  background: -o-linear-gradient(top, #009cda, #0073b2);
  background: -webkit-linear-gradient(top, #009cda, #0073b2)
}
.postshare a.wa_btn.wa_btn_m {
  background-color: #4dc247;
  background: -moz-linear-gradient(top, #55d94f, #4dc247);
  background: -ms-linear-gradient(top, #55d94f, #4dc247);
  background: -webkit-linear-gradient(top, #55d94f, #4dc247);
  background: -o-linear-gradient(top, #55d94f, #4dc247)
}
.blog-sidebar .widget {
  padding: 20px 30px;
  background-color: #fff;
}
.blog-sidebar .post-thumb img {
  width: 110px;
}
.blog-sidebar h6 {
  line-height: 1.5;
}
.blog-sidebar .search-form .field-search {
  position: relative;
}
.blog-sidebar .search-form .field-search input {
  padding-right: 20px;
}
.blog-sidebar .search-form .field-search .btn-search {
  position: absolute;
  top: 12px;
  right: 15px;
  color: #aaa;
}
.blog-sidebar .sidebar-list li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #d3d3d3;
}
.blog-sidebar .sidebar-list li a {
  display: block;
  font-weight: 300;
  padding-left: 20px;
  position: relative;
}
.blog-sidebar .sidebar-list li a:after {
  content: "\f0da";
  font-family: "FontAwesome";
  font-size: 15px;
  line-height: 17px;
  position: absolute;
  left: 2px;
  top: 3px;
}
.blog-sidebar .tags-list a {
  background-color: #97a0af;
  color: #fff;
  padding: 5px 10px;
  border-radius: 2px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  text-decoration: none;
}
.comment {
  position: relative;
  overflow: hidden;
  margin: 0 0 30px;
  padding-left: 80px;
}
.comment:last-child {
  margin-bottom: 0;
}
.comment .comment-avatar {
  width: 60px;
  position: absolute;
  left: 0;
  top: 4px;
}
.comment .comment-avatar img {
  border-radius: 999px
}
.comment .comment-meta {
  position: absolute;
  right: 0;
}
.comment .comment-author,
.comment .comment-reply-link {
  display: inline-block;
  font-size: 12px;
  padding: 4px 20px;
  text-transform: uppercase;
  border-radius: 40px;
}
.comment .comment-author {
  color: #ffa91e;
  background: #fff1da;
}
.comment .comment-reply-link {
  color: #00aefd;
  background: #e8f8ff;
}
.comment .comment-reply-link:hover {
  color: #fff;
  background: #2b2c2d
}
.comment .comment-name {
  font-size: 17px;
  color: #383842
}
.comment .comment-date {
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 10px;
  color: #a7aeb6;
}
.comment .children {
  margin: 30px 0 0;
}
.comment .children > .comment:last-child {
  margin-bottom: 0
}

/* --------------------------------------------- */
/* ------------>>> Footer Area  <<<---------- */
/* --------------------------------------------- */

.main-footer {
  background-color: #1b2737;
  color: #eee;
}
.main-footer .sub-footer {
  background-color: rgba(3, 7, 16, 0.5);
  padding: 25px 0;
}
.main-footer .sub-footer .copyright {
  margin: 0;
  text-align: center;
}
.main-footer .menu-widget li a {
  display: block;
  padding: 8px 5px;
  color: #f1f1f1;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
}
.main-footer .menu-widget li:first-child a {
  border-top: 1px dashed rgba(255, 255, 255, 0.2);
}
.main-footer .footer-widgets .widget .social-icons__item .fa {
  background-color: rgba(255, 255, 255, 0.32);
}
.main-footer .footer-widgets .widget.get-in-touch h6 {
  color: #f1f1f1;
  margin-bottom: 0px;
}
.main-footer .footer-widgets .widget.get-in-touch h6 span {
  color: #94CF3E;
  margin-right: 5px;
}
.main-footer .footer-widgets .widget.get-in-touch h6.first-par {
  margin-bottom: 10px;
}